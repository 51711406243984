import styled from "styled-components";

export function Andreani({ url, etiqueta }) {
  return (
    <Container>
      <div className="data">
        <div className="group">
          <span className="label">ID Sucursal</span>
          <p className="value">{etiqueta.id_sucursal}</p>
        </div>

        <div className="group">
          <span className="label">Sucursal</span>
          <p className="value">{etiqueta.descripcion_sucursal}</p>
        </div>

        <div className="group">
          <span className="label">Nomenclatura sucursal</span>
          <p className="value">{etiqueta.nomenclatura_sucursal}</p>
        </div>

        <div className="group">
          <span className="label">nro. envio</span>
          <p className="value">{etiqueta.numero_envio}</p>
        </div>
      </div>

      <div className="container">
        {url ? (
          <iframe width="100%" height="600px" src={url} />
        ) : (
          <div>
            <h2>Pedido no enviado a Andreani</h2>
          </div>
        )}
      </div>
    </Container>
  );
}

export const Container = styled.div`
  margin: 1rem 0 2rem 0;

  .data {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  .group {
    .label {
      font-weight: 600;
      text-transform: capitalize;
    }
    .value {
      display: block;
      width: 100%;
      padding: 1rem;
      background: #f0f0f0;
      border-radius: 5px;
    }
  }
`;
