import { BiExpandAlt, BiFile, BiPlus } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FaTimes } from "react-icons/fa";

import PopUp from "../PopUp";

import colors from "../../styles/colors";

const formats = ["jpg", "jpeg", "png", "svg"];

function FileCard({
	multiple = true,
	onChange,
	filePrefix,
	images,
	className,
	editable = false,
}) {
	const [_expand, _setExpand] = useState(null);
	const [_images, _setImages] = useState(images);
	const [_newImages, _setNewImages] = useState([]);

	const [_toRemove, _setToRemove] = useState([]);

	const removeImage = (n, isNew) => {
		if (!isNew) {
			const img = _images.filter((_, i) => i === n)[0];

			_setToRemove((old) => {
				return [
					{
						id: img.id,
						type:
							img.mimetype.includes("image") || img.mimetype.includes("pdf")
								? "image"
								: "file",
					},
					...old,
				];
			});
		}

		const remove = (arr) =>
			arr.map((img, i) => (i === n ? null : img)).filter((i) => !!i);

		if (isNew) return _setNewImages(remove);
		return _setImages(remove);
	};

	useEffect(() => {
		if (onChange) onChange(_newImages, _toRemove);
	}, [_images, _newImages]);

	const addImages = ({ target: { files } }) => {
		_setNewImages((old) => [...Array.from(files), ...old]);
	};

	return editable ? (
		<EditableContainer>
			<Container as="label" placeholder>
				<input onChange={addImages} type="file" multiple={multiple} />
				{multiple || (_images.length === 0 && _newImages.length === 0) ? (
					<div className="icon-container">
						<BiPlus size={30} />
					</div>
				) : null}
			</Container>

			{_newImages &&
				_newImages.map((image, i) => {
					if (!image) return null;
					const url = URL.createObjectURL(image);
					const type = "png";
					return (
						<Container>
							<div className="overlay" />
							<RemoveImage type="button" onClick={() => removeImage(i, true)}>
								<FaTimes size={16} />
							</RemoveImage>

							{formats.includes(type.toLowerCase()) ? (
								<img src={url} />
							) : (
								<FileContainer>
									<div>
										<BiFile size={20} />
										{type && <span>{type}</span>}
									</div>
								</FileContainer>
							)}
						</Container>
					);
				})}

			{_images &&
				_images.map((image, i) => {
					if (!image) return null;
					let data = null;
					if (image.images) data = image.images.data;
					if (image.image) data = image.image.data;
					if (image.file) data = image.file.data;
					if (!data) return null;

					const { url, type } = getURL(data, filePrefix, i, image.mimetype);
					return (
						<Container>
							<div className="overlay" />
							<RemoveImage type="button" onClick={() => removeImage(i)}>
								<FaTimes size={16} />
							</RemoveImage>

							{formats.includes(type.toLowerCase()) ? (
								<img src={url} />
							) : (
								<FileContainer>
									<div>
										<BiFile size={20} />
										{type && <span>{type}</span>}
									</div>
								</FileContainer>
							)}
						</Container>
					);
				})}
		</EditableContainer>
	) : (
		<div className={className}>
			{_expand && (
				<PopUp onClick={() => _setExpand(null)} full>
					{_expand.type === "pdf" && (
						<iframe src={_expand.url} width="100%" height="800px" />
					)}
					{_expand.type !== "pdf" && <FullImage src={_expand.url} alt="" />}
				</PopUp>
			)}
			{_images &&
				_images.map((image, i) => {
					if (!image) return null;
					let data = null;
					if (image.images) data = image.images.data;
					if (image.image) data = image.image.data;
					if (image.file) data = image.file.data;
					if (!data) return null;

					const { url, type } = getURL(data, filePrefix, i, image.mimetype);

					return (
						<a href={url} download={true}>
							<span className="overlay">
								<BiExpandAlt size={20} />
							</span>
							{formats.includes(type.toLowerCase()) ? (
								<img src={url} />
							) : (
								<FileContainer>
									<div>
										<BiFile size={20} />
										{type && <span>{type}</span>}
									</div>
								</FileContainer>
							)}
						</a>
					);
				})}
		</div>
	);
}

export default FileCard;

const FileContainer = styled.div`
	display: grid;
	place-items: center;
	background: #f0f0f0;
	border-radius: 5px;
	overflow: hidden;
	height: 100%;
	min-height: 6rem;
	aspect-ratio: 1/1;

	> * {
		display: flex;
		flex-flow: column;
		align-items: center;
		gap: 0.25em;

		span {
			font-size: 0.9rem;
			text-decoration: none;
		}
	}
`;

function getURL(data, prefix, sufix, mimetype) {
	const buffer = Buffer.from(data);

	const name = `${`${prefix}-` || ""}${sufix + 1}`;

	const type = mimetype
		? mimetype.split("/")[1] || (mimetype === "image" ? "jpg" : "")
		: "";

	const fileType = [
		"vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"vnd.ms-excel",
	].includes(type)
		? "xls"
		: type;

	const nameWithType = `${name}${fileType ? `.xls` : ""}`;

	const file = new File([buffer], nameWithType, { type: mimetype });

	return { url: URL.createObjectURL(file), type: fileType };
}

const EditableContainer = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1em;
	width: 100%;
`;
const Container = styled.div`
	position: relative;
	width: 100%;
	aspect-ratio: 1/1;

	input[type="file"] {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
	}

	img {
		display: block;
		width: 100%;
		aspect-ratio: 1/1;
		object-fit: cover;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(-45deg, #0000, #000a);
	}

	${({ placeholder }) =>
		placeholder &&
		css`
			border-radius: 7px;
			border: 2px dashed #${colors.hex.primary};
			display: grid;
			place-items: center;

			.icon-container {
				color: #${colors.hex.primary};
			}
		`}
`;
const RemoveImage = styled.button`
	position: absolute;
	top: 0;
	left: 0;
	width: 1.5em;
	height: 1.5em;
	display: grid;
	place-items: center;

	border: none;
	border-radius: 0;

	color: #fff;
	background: transparent;
`;

const FullImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: contain;
`;
