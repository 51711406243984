import { FILTER_WAREHOUSE } from "../Actions/ActionsTypes";

const initialState = {
  stocks: [],
  length: 0,
  systems: {
    wms: false,
    tango: false,
  },
};

export default function root(state = initialState, action) {
  switch (action.type) {
    case FILTER_WAREHOUSE:
      return {
        ...state,
        systems: action.payload.data.loaded,
        stocks: action.payload.data.stocks,
        length: action.payload.data.length,
      };
    default:
      return state;
  }
}
