import React, { useState } from "react";

import FileCard from "../../Files/FileCard";
import UpdateHandler from "./UpdateHandler";

import { DetailsContainer } from "./styles";

import useShow from "../../../hooks/useShow";
import Loader from "../../Loader";

function Upload() {
  const [inputs, setInputs] = useState({});

  const { state, show, hide } = useShow({ init: false });

  const _onEvent = (type) => {
    if (type === "creating") show();
    if (type === "created") hide();
  };

  const handleImages = (arr) => {
    setInputs((old) => ({
      ...old,
      file: arr,
    }));
  };

  return !state ? (
    <DetailsContainer>
      <div className="images-container">
        <FileCard
          onChange={handleImages}
          editable={true}
          filePrefix={`file`}
          className="images"
          images={[]}
        />
      </div>

      <UpdateHandler
        create={true}
        data={inputs}
        isEditable={false}
        onEvent={_onEvent}
      />
    </DetailsContainer>
  ) : (
    <Loader text={"ACTUALIZANDO STOCK"} />
  );
}

export default Upload;
