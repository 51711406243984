import Cookie from "universal-cookie";
import { getLocal, removeLocal, saveLocal } from "../../Utils/storage";
import { LOGIN, LOGOUT } from "../Actions/ActionsTypes";

const SESSION_COOKIE = "_logistic_session";
const cookie = new Cookie();
const initialState = getLocal(SESSION_COOKIE) || null;

export default function root(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      const { data } = action.payload;
      saveLocal(SESSION_COOKIE, data.user);
      cookie.set(SESSION_COOKIE, data.token);
      return data.user;

    case LOGOUT:
      cookie.remove(SESSION_COOKIE);
      removeLocal(SESSION_COOKIE);
      return null;

    default:
      return state;
  }
}
