export const fromDataInput = (object) => {
  const formData = new FormData();
  for (const input in object) {
    if (object[input]) {
      if (input === "file") {
        object[input].forEach((file) => {
          formData.append("file", file);
        });
      } else if (input === "images") {
        object[input].forEach((image) => {
          formData.append("file", image);
        });
      } else if (
        input !== "comments" &&
        input !== "seeBy" &&
        input !== "pricefuel"
      )
        formData.append(input, object[input]);
    }
  }
  return formData;
};
