import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";
import axios from "axios";
import Table from "../../Components/Table";

export function Warehouse23() {
  const navigate = useNavigate();
  const session = useSelector((store) => store.auth);

  const [depo23, setDepo23] = useState({
    isLoading: true,
    data: [],
    error: null,
  });

  useEffect(() => {
    if (!session) return navigate("/");

    const p = async () => {
      setDepo23((old) => ({ ...old, isLoading: true, error: null }));
      try {
        const { data } = await axios.get(
          `https://api2.servicomglobal.com/api/warehouse/depo23`,
          {
            params: { from: 0, to: 100 },
          }
        );
        setDepo23({ data: data.products, isLoading: false });
      } catch (e) {
        setDepo23({
          data: [],
          isLoading: false,
          error: "Ocurrio un error al traer los datos.",
        });
      }
    };

    p();
  }, [session]);

  const COLUMNS = [
    { value: "Cod. Producto" },
    { value: "Familia" },
    { value: "Marca" },
    { value: "Modelo" },
    { value: "Variante" },
    { value: "Tipografia" },
    { value: "Origen" },
    { value: "Gama" },
    { value: "Deposito 23" },
    { value: "WMS" },
    { value: "Diferencia" },
  ];
  return (
    <Container>
      <div className="header">
        <h2>Deposito 23</h2>
      </div>

      {depo23.isLoading ? <p>Cargando stock...</p> : null}
      {depo23.error ? <p>{depo23.error}</p> : null}

      {!depo23.isLoading && depo23.data ? (
        <Table
          columns={COLUMNS}
          rows={depo23.data.map((p) => [
            { value: p.cood },
            { value: p.family },
            { value: p.brand },
            { value: p.model },
            { value: p.variant },
            { value: p.typography },
            { value: p.origin },
            { value: p.Gama },
            { value: p.warehouse23 || 0 },
            { value: p.wms.stock || 0 },
            { value: (p.wms.warehouse23 || 0) - (p.wms.stock || 0) },
          ])}
        />
      ) : (
        <div></div>
      )}
    </Container>
  );
}

export const Container = styled.div`
  padding: 1rem 0 0 calc(var(--navbar-size) + 1rem);
  min-height: 100vh;

  .header {
    margin: 0 0 1rem 0;

    h2 {
      font-size: 1.15rem;
    }
  }
`;
