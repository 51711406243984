import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PopUp from "../../PopUp";

import Table from "../../Table";
import { findAllOrders } from "../../../Redux/Actions/Orders";
import { Link } from "react-router-dom";
import axios from "axios";
import { PORT_API } from "../../../Redux/Actions/ActionsTypes";
import { useAuth } from "../../../hooks/useAuth";

export default function EnviarWMS() {
  const user = useAuth();
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.orders);
  const [enviar, setEnviar] = useState({ pedidos: [], isLoading: false });
  const [handler, setHandler] = useState({ isLoading: false });

  const [filters, setFilters] = useState({ nro_pedido: "", date: "" });
  const onChange = ({ target: { name, value } }) => {
    setFilters((old) => ({ ...old, [name]: value }));
  };

  const sendAll = async () => {
    if (!user?.rol_logistica?.roles?.includes("etiquetas")) return null;

    setHandler({ isLoading: true });

    const promises = enviar.pedidos.map(async (p) =>
      axios.post(`${PORT_API}/pedidos/send/wms`, { nro_pedido: p })
    );

    await Promise.all(promises);
    dispatch(findAllOrders());

    setHandler({ isLoading: false });
  };

  const onSend = async (nro_pedido) => {
    if (!user?.rol_logistica?.roles?.includes("etiquetas")) return null;
    if (!nro_pedido) return null;

    setHandler({ isLoading: true });
    await axios.post(`${PORT_API}/pedidos/send/wms`, {
      nro_pedido,
    });

    dispatch(findAllOrders());
    setHandler({ isLoading: false });
  };

  useEffect(() => {
    dispatch(findAllOrders());
  }, [dispatch]);

  const columns = [
    { value: "Nro. Pedido" },
    { value: "Fecha." },
    { value: "Cod. Cliente" },
    { value: "Razon Social" },
    { value: "Transporte" },
    ...(user?.rol_logistica?.roles?.includes("etiquetas")
      ? [
          { value: "Enviar a WMS" },
          {
            value: (
              <label>
                <input
                  onChange={({ target: { checked } }) =>
                    setEnviar(() => ({
                      pedidos: checked ? orders.map((o) => o.nro_pedido) : [],
                      isLoading: false,
                    }))
                  }
                  type="checkbox"
                  checked={enviar.pedidos.length === orders.length}
                />
              </label>
            ),
          },
        ]
      : []),
  ];

  const onCheck = ({ target: { checked, name } }) => {
    setEnviar((old) => ({
      ...old,
      pedidos: checked
        ? [name, ...old.pedidos]
        : old.pedidos.filter((_p) => _p !== name),
    }));
  };

  return (
    <TableContainer>
      {handler.isLoading ? (
        <div className="popup">
          <PopUp full center>
            <h2>Enviando Pedidos a WMS</h2>
          </PopUp>
        </div>
      ) : null}

      <div className="header">
        <h2>Pedidos Retail</h2>

        {enviar.pedidos.length > 0 ? (
          <div className="global-send">
            <button onClick={sendAll}>Enviar pedidos</button>
            <p>Total seleccionados: {enviar.pedidos.length || "0"}</p>
          </div>
        ) : (
          <div />
        )}
      </div>

      <div className="filters">
        <label>
          <input
            onChange={onChange}
            name="nro_pedido"
            type="text"
            placeholder="Buscar por nro. pedido o cod. cliente"
            value={filters.nro_pedido}
          />
        </label>

        <label>
          <input
            onChange={onChange}
            name="date"
            type="text"
            placeholder="dd/mm/yyyy"
            value={filters.date}
          />
        </label>
      </div>

      <Table
        columns={columns}
        rows={
          orders.length
            ? orders
                .map((o) => ({
                  ...o,
                  checked: !!enviar.pedidos.find((e) => e === o.nro_pedido),
                }))
                .filter((p) => {
                  if (filters.date) {
                    const [day, month, year] = filters.date.split("/");
                    if (day && month && year)
                      return p.fecha_pedido.includes(`${year}-${month}-${day}`);
                  }

                  if (filters.nro_pedido) {
                    return (
                      p.nro_pedido.includes(filters.nro_pedido) ||
                      p.cod_cliente.includes(filters.nro_pedido)
                    );
                  }

                  return true;
                })
                .map((e) => {
                  return [
                    {
                      value: (
                        <Link to={`/pedido/${e.nro_pedido.trim()}`}>
                          {e.nro_pedido}
                        </Link>
                      ),
                    },

                    {
                      value: `${printDate(e.fecha_pedido).split(",")[0] || ""}`,
                    },
                    { value: `${e.cod_cliente.toString() || ""}` },
                    { value: `${e.razon_social || ""}` },
                    { value: `${e.transporte || ""}` },
                    ...(user?.rol_logistica?.roles?.includes("etiquetas")
                      ? [
                          {
                            value: (
                              <button onClick={() => onSend(e.nro_pedido)}>
                                Enviar
                              </button>
                            ),
                          },
                          {
                            value: (
                              <div>
                                <input
                                  name={e.nro_pedido}
                                  type="checkbox"
                                  onChange={onCheck}
                                  checked={e.checked}
                                />
                              </div>
                            ),
                          },
                        ]
                      : []),
                  ];
                })
            : []
        }
        download={false}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  overflow-x: auto;

  .popup {
    .content {
      padding: 1rem;
      width: max-content;
    }
  }

  .global-send {
    display: flex;
    flex-flow: column;
    align-items: flex-end;

    button {
      background: #000;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 1em;
      border-radius: 5px;
      padding: 0.75em 1em;
      border: none;
    }
  }

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 2em;
    margin: 1em 0;

    h2 {
      font-size: 1em;
    }
  }

  > .filters {
    display: flex;
    gap: 1rem;

    label {
      border-radius: 5px;
      border: 1px solid #aaa;
      overflow: hidden;
    }

    input {
      border: none;
      padding: 0.5rem 0.75rem;
    }
  }
`;

function printDate(str) {
  const date = new Date(str);
  return date.toLocaleString();
}
