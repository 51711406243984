import { FIND_ALL_ORDERS } from "../Actions/ActionsTypes";

const initialState = {
  orders: [],
};

export default function root(state = initialState, action) {
  switch (action.type) {
    case FIND_ALL_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
      };

    default:
      return state;
  }
}
