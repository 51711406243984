import { Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  padding: 0 1em 0 0;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  height: var(--navbar-size);
  width: 100%;
  background: var(--primary);

  &,
  .left,
  .middle,
  .right {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .left,
  .middle,
  .right {
    height: 100%;
    width: 100%;
  }

  .middle {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }

  .icon {
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  ul,
  li {
    list-style: none;
  }

  .link {
    position: relative;
    background: transparent;
    border: none;
    --size: 3rem;
    padding: 0.75em;
    width: var(--size);
    height: var(--size);
    color: #fff;
    opacity: 0.35;
    transition: opacity 0.15s ease, transform 0.25s ease;

    :hover {
      opacity: 1;
    }

    ::after {
      content: "";
      position: absolute;
      bottom: 2em;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 100%;
      opacity: 0;
      transition: all 0.25s;
    }
  }
  .link.active {
    transform: translate(0, -5px);
    opacity: 1;
    cursor: default;

    ::after {
      bottom: 0;
      opacity: 1;
    }
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  width: 9rem;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
  padding: 0.25em 2.5em 0.25em 1em;

  img {
    display: block;
    width: 100%;
  }
`;
