import styled from "styled-components";
import React from "react";

import colors from "../../../styles/colors";

export default function NavBarToolsCard({ roles, links, onClick, active }) {
  return (
    <Nav>
      {links.map((l) =>
        l.category
          ? l.links.find((link) => roles.includes(link.role)) && (
              <div key={`nav-category-${l.category}`} className="category">
                <h3 className="category-name">{l.category}</h3>

                <div className="category-links">
                  {l.links.map(
                    (_link) =>
                      roles.includes(_link.role) && (
                        <Link
                          key={`nav-item-${_link.link}`}
                          label={_link.label}
                          onClick={() => onClick(_link.link)}
                          icon={_link.icon}
                          isActive={new RegExp(`^${_link.link}`).test(active)}
                        />
                      )
                  )}
                </div>
              </div>
            )
          : roles.includes(l.role) && (
              <Link
                key={`nav-item-${l.link}`}
                label={l.label}
                onClick={() => onClick(l.link)}
                icon={l.icon}
                isActive={new RegExp(`^${l.link}`).test(active)}
              />
            )
      )}
    </Nav>
  );
}

const Nav = styled.nav`
  position: fixed;
  top: var(--navbar-size);
  left: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 0.5em 0 0 0;
  background: #fff;
  z-index: 5000;
  width: var(--navbar-size);
  overflow: hidden;
  transition: width 0.15s;

  .link {
    display: flex;
    align-items: center;
    background: transparent;
    justify-content: flex-start;
    text-align: left;
    border: none;
    width: 100%;

    :not(.active):hover {
      background: #0001;
    }

    .icon-container {
      position: relative;
      display: grid;
      place-items: center;
      width: var(--navbar-size);
      min-width: var(--navbar-size);
      padding: 0.75em 0;

      .icon {
        padding: 0;
        z-index: 2;
      }

      ::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 5px;
        height: 0;
        background: #${colors.hex.primary}00;
        transition: all 0.25s;
        border-radius: 0 2px 2px 0;
      }
    }

    .label {
      white-space: nowrap;
      padding: 0 0 0 0.5rem;
    }
  }

  a {
    text-decoration: none;
  }

  .active {
    color: #${colors.hex.primary};
    cursor: default;

    .icon-container {
      .icon {
        z-index: 2;
      }

      ::after {
        height: 50%;
        background: #${colors.hex.primary};
      }
    }
  }

  .category {
    position: relative;

    .category-name {
      white-space: nowrap;
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: capitalize;
      transform: translate(-100%, 0);
      transition: all 0.15s;
      opacity: 0;
      padding: 0 0 0.25rem 0.5rem;
    }

    .category-links {
      background: #00000005;
    }
  }

  .category:not(:first-child) {
    .category-name {
      padding: 1rem 0 0.25rem 0.5rem;
    }
  }

  :hover {
    width: 15rem;

    .category-name {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;

function Link({ label, icon, onClick, isActive, href }) {
  const Icon = icon || null;
  const Component = href ? "a" : "button";

  return (
    <Component
      className={`link ${isActive ? "active" : ""}`}
      {...(href
        ? { href, target: "_blank", rel: "noreferrer" }
        : { onClick, disabled: !!isActive })}
    >
      <div className="icon-container">{Icon && <Icon size={22} />}</div>
      <span className="label">{label}</span>
    </Component>
  );
}
