import React, { useState } from "react";

import { FaUserCircle, FaKey, FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";

import { login } from "../../../Redux/Actions/Auth";
import { validateLogin } from "../../../Utils/validate";
import useNavigateOnAuth from "../../../hooks/useNavigateOnAuth";
import { AuthCardContainer } from "../../../styles/Auth.styles";

const initErrors = { email: "", password: "", code: "" };

export default function LoginCard() {
  const dispatch = useDispatch();

  useNavigateOnAuth();

  const [errors, setErrors] = useState(initErrors);
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setInput((old) => ({ ...old, [e.target.name]: e.target.value }));
    setErrors("");
  };
  const [isBlocked, setIsBlocked] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = validateLogin(input);

    if (email || password) {
      setErrors((old) => ({
        ...old,
        email: email || "",
        password: password || "",
      }));
      email
        ? setInput({ email: "", password: "" })
        : setInput((old) => ({
            ...old,
            password: "",
          }));
    } else {
      setIsBlocked(true);
      const res = await dispatch(login(input));
      setIsBlocked(false);
    }
  };

  return (
    <AuthCardContainer onSubmit={onSubmit}>
      <div className="container">
        <h1>Iniciar sesion</h1>

        <div className="inputs">
          <Input
            name="email"
            label="Email"
            icon={FaUserCircle}
            onChange={onChange}
            value={input.email}
            type="email"
            placeholder="Ingrese email"
            error={errors.email}
          />

          <Input
            name="password"
            label="Contraseña"
            icon={FaKey}
            onChange={onChange}
            value={input.password}
            type="password"
            placeholder="Ingrese contraseña"
            error={errors.password}
          />
        </div>

        <div>{errors.password && <span>{errors.password}</span>}</div>
        <div>{errors.code && <span>{errors.code}</span>}</div>

        <div className="buttons">
          <button className="btn primary" type="submit" disabled={isBlocked}>
            Iniciar Sesion
          </button>
          <span className="divider">O</span>
        </div>
      </div>
    </AuthCardContainer>
  );
}

function Input({
  type,
  value,
  label,
  error,
  icon,
  placeholder,
  onChange,
  name,
}) {
  const [_type, setType] = useState(type);
  const Icon = icon || null;

  const toggle = () => setType((t) => (t === "password" ? "text" : "password"));

  return (
    <label className="input">
      <span className="name">{label}</span>
      <div className="input-container">
        <div className="icon-container">{Icon && <Icon />}</div>
        <input
          onChange={onChange}
          name={name}
          type={_type}
          value={value}
          placeholder={placeholder}
        />
        {type === "password" && (
          <button className="see-password" type="button" onClick={toggle}>
            <FaEye />
          </button>
        )}
      </div>
      <span className="error">{error}</span>
    </label>
  );
}
