function usePagination(length) {
  const ROWS = 50;

  const index = [];
  const div = Math.trunc(length / ROWS);

  for (let i = 0; i <= div; i++) {
    const finish = (i + 1) * ROWS - 1;
    index.push({ start: i * ROWS, finish });
  }

  const finish = index[div].finish;
  if (finish < length) {
    index.push({ start: finish + 1, finish: length });
  }

  return index;
}

export default usePagination;
