import { FIND_ALL_ORDERS, PORT_API } from "./ActionsTypes";
import axios from "axios";

export function findAllOrders() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${PORT_API}/pedidos/sin-enviar`);
      dispatch({ type: FIND_ALL_ORDERS, payload: response });
    } catch (e) {
      return e.response.data;
    }
  };
}
