import styled from "styled-components";

export const TableContainer = styled.div`
  width: 95%;
  margin: 0 auto;

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 2em;
    margin: 1em 0;

    h2 {
      font-size: 1em;
    }
  }

  .filters {
    display: flex;
    gap: 1rem;

    input {
      border-radius: 5px;
      padding: 0.75rem 1rem;
      min-width: 280px;
      background: #fafafa;
      border: none;
    }
  }
`;
