import styled from "styled-components";
import colors from "./colors";

export const AuthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100%;
  width: 100%;
  .left {
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

export const AuthCardContainer = styled.form`
  width: 90%;
  max-width: 480px;
  height: 100%;
  margin: 0 auto;
  padding: 0 5em;
  display: grid;
  place-items: center;
  border-radius: 5px;
  h1,
  .title {
    font-size: 1em;
    border: none;
    text-align: center;
    padding: 2em 0;
  }
  .title {
    padding: 0.5em 0;
  }
  .description {
    width: 100%;
    text-align: center;
    font-size: 0.75em;
    color: #aaa;
    margin: 0 0 2em 0;
  }
  .buttons {
    margin: 2em 0 0 0;
    display: flex;
    flex-flow: column;
    gap: 0.75em;
    button {
      background: transparent;
      border: none;
    }
    .divider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;
      font-size: 0.75em;
      color: #0003;
      ::before,
      ::after {
        content: "";
        display: block;
        background: #0002;
        height: 1px;
        width: 25%;
      }
    }
    .primary,
    .secondary {
      padding: 0.75em 0;
    }
    .primary {
      background: #${colors.hex.primary};
      color: #fff;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 0.95rem;
      border-radius: 2px;
      transition: background 0.15s;
    }
    .primary:hover {
      background: #${colors.hex.primary_400};
    }
    .secondary {
    }
  }
  .pass-recovery {
    display: block;
    background: transparent;
    border: none;
    margin: 0 0 0 auto;
    font-size: 0.65em;
    color: #888;
    :hover {
      color: #000;
      text-decoration: underline;
    }
  }
  .inputs {
    margin: 0 0 0.25em 0;
    display: flex;
    flex-flow: column;
    gap: 1em;
  }
  .input {
    padding: 0;
    .name {
      display: block;
      background: transparent !important;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      font-size: 0.85em;
      padding: 0.25em 0;
    }
    .input-container {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid #0005;
      border-radius: 5px;
      cursor: text;
      .icon-container {
        --size: 2.5em;
        display: grid;
        place-items: center;
        width: var(--size);
        height: var(--size);
        .icon {
        }
      }
      input {
        display: block;
        height: 100%;
        padding: 0;
        border: none;
        outline: none;
      }
    }
    .input-container:focus-within {
      outline: 2px solid #5ac;
    }
    .error {
      display: block;
      width: 100%;
    }
  }
  .see-password {
    width: 2.5em;
    height: 2.5em;
    background: transparent;
    border: none;
  }
`;
