import axios from "axios";
import {
  FILTER_WAREHOUSE,
  PORT_API,
  UPLOAD_FILE_WAREHOUSE,
  URL_DOWNLOAD_FILE_WAREHOUSES,
} from "./ActionsTypes";
import { writeFileXLSX } from "xlsx";

export function filterWarehouse({ start, finish, cood }) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${PORT_API}/warehouse/filter`, {
        params: {
          from: start,
          to: finish,
          cood: cood,
        },
      });
      dispatch({ type: FILTER_WAREHOUSE, payload: response });
    } catch (e) {
      return e.response.data;
    }
  };
}

export function downloadFile(type) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${PORT_API}/${URL_DOWNLOAD_FILE_WAREHOUSES}/${type}`
      );

      writeFileXLSX(data, "warehouse.xlsx");
      dispatch({ type: "NO-ACTION", payload: null });
    } catch (e) {
      dispatch({ type: "NO-ACTION", payload: null });
    }
  };
}

export function updateStockFromFile(data) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${PORT_API}/wms/upload`, data);
      dispatch({ type: UPLOAD_FILE_WAREHOUSE, payload: response });
      return response.data;
    } catch (e) {
      return {
        type: "NO-ACTION",
        payload: null,
      };
    }
  };
}
