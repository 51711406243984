import styled, { createGlobalStyle, css } from "styled-components";
import colors from "./colors";

const GlobalStyle = createGlobalStyle`
  :root {
    --navbar-size: 3.5rem;
    --primary: #${colors.hex.primary};
    --dark: #${colors.hex.dark};
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }
  html {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }
  body {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
  }
  a, button {
    color: inherit;
    cursor: pointer;
  }
  input{
    padding: 1em;
  }
`;

export default GlobalStyle;

export const Status = styled.div`
  text-transform: capitalize;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  margin: 0 0 0.5em 0;
  ${({ value }) =>
    value === "approved" &&
    css`
      background: #5a5;
    `}
  ${({ value }) =>
    value === "reject" &&
    css`
      background: #f55;
    `}
  ${({ value }) =>
    value === "pendding" &&
    css`
      background: #aaa;
    `}
`;
