import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { PORT_API } from "../../../Redux/Actions/ActionsTypes";
import Table from "../../Table";
import styled from "styled-components";
import Select from "react-select";
import { useSelector } from "react-redux";

const columns = [
  { value: "Nro. Pedido" },
  { value: "Remito" },
  { value: "Fecha Pedido" },
  { value: "Cod. Cliente" },
  { value: "Razon Social" },
  { value: "Estado WMS" },
  { value: "Estado Etiqueta" }, // generada o no generada
  { value: "Estado Envio Andreani" }, //
  { value: "C. Bultos" }, //
  { value: "KV" }, //
  { value: "Fecha Actualizacion." },
];

function printDate(str) {
  const date = new Date(str);
  return date.toLocaleString();
}

function printStatus(arr = []) {
  const anulado = arr.find(({ status }) => status === "anulado");
  if (anulado)
    return {
      name: "anulado",
      date: new Date(anulado.created_at).toLocaleString(),
    };

  const despachado = arr.find(({ status }) => status === "despachado");
  if (despachado)
    return {
      name: "despachado",
      data: JSON.parse(despachado.data || "{}"),
      date: new Date(despachado.created_at).toLocaleString(),
    };

  const preparado = arr.find(({ status }) => status === "preparado");
  if (preparado)
    return {
      name: "preparado",
      date: new Date(preparado.created_at).toLocaleString(),
    };

  const pendiente = arr.find(({ status }) => status === "pendiente");
  if (pendiente)
    return {
      name: "pendiente",
      date: new Date(pendiente.created_at).toLocaleString(),
    };
  return null;
}

const init = { isLoading: true, data: [] };
export function PedidosEnviados() {
  const session = useSelector((store) => store.auth);
  const [pedidos, setPedidos] = useState(init);

  const [filters, setFilters] = useState({
    nro_pedido: "",
    date: "",
    status: "todos",
  });
  const onChange = ({ target: { name, value } }) => {
    setFilters((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    const p = async () => {
      try {
        setPedidos(init);
        const { data } = await axios.get(`${PORT_API}/pedidos/enviados`, {
          params: { email: session.email },
        });
        setPedidos({ isLoading: false, data });
      } catch (e) {
        setPedidos({ isLoading: false, data: e.message });
      }
    };
    p();
  }, []);

  return (
    <TableContainer>
      <div className="header">
        <h2>Pedidos Enviados</h2>
      </div>

      <div className="filters">
        <label>
          <input
            onChange={onChange}
            name="nro_pedido"
            type="text"
            placeholder="Buscar por nro. pedido o cod. cliente"
            value={filters.nro_pedido}
          />
        </label>

        <label>
          <input
            onChange={onChange}
            name="date"
            type="text"
            placeholder="dd/mm/yyyy"
            value={filters.date}
          />
        </label>

        <Select
          onChange={(opt) =>
            setFilters((old) => ({ ...old, status: opt.value }))
          }
          options={[
            { value: "todos", label: "Todos" },
            { value: "pendiente", label: "Pendiente" },
            { value: "preparado", label: "Preparado" },
            { value: "despachado", label: "Despachado" },
            { value: "anulado", label: "Anulado" },
          ]}
          placeholder="Estado"
        />
      </div>

      <Table
        columns={columns}
        rows={
          pedidos.data.length
            ? pedidos.data
                .filter((p) => {
                  let isStatus = false;
                  if (
                    filters.status === "todos" ||
                    printStatus(p.wms.status)?.name === filters.status
                  )
                    isStatus = true;

                  if (filters.date) {
                    const [day, month, year] = filters.date.split("/");
                    if (day && month && year)
                      return p.fecha_pedido.includes(`${year}-${month}-${day}`);
                  }

                  if (filters.nro_pedido) {
                    return (
                      p.nro_pedido.includes(filters.nro_pedido) ||
                      p.cod_cliente.includes(filters.nro_pedido)
                    );
                  }

                  return isStatus && true;
                })
                .map((e) => [
                  {
                    value: (
                      <Link to={`/pedido/${e.nro_pedido}`}>{e.nro_pedido}</Link>
                    ),
                  },
                  { value: e.remito || "-" },
                  { value: `${printDate(e.fecha_pedido).split(",")[0] || ""}` },
                  { value: `${e.cod_cliente || ""}` },
                  { value: `${e.razon_social || ""}` },
                  { value: `${printStatus(e.wms.status)?.name || "-"}` },
                  { value: `${e.numero_envio ? "Generada" : "No Generada"}` },
                  {
                    value: (
                      <>
                        {e.numero_envio && "Enviada"}
                        {!e.numero_envio && !e.remito
                          ? "Falta remito"
                          : !e.numero_envio && e.TotalAforo === 0
                          ? "Articulo no tiene aforo"
                          : ""}
                        {}
                      </>
                    ),
                  },
                  {
                    value:
                      printStatus(e.wms.status)?.name === "despachado"
                        ? printStatus(e.wms.status)?.data.bultos || "-"
                        : "-",
                  },
                  {
                    value: Number(e.TotalAforo)?.toFixed(2) || "0",
                  },
                  { value: `${printStatus(e.wms.status)?.date || "-"}` },
                ])
            : []
        }
        download={false}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  width: 95%;
  margin: 0 auto;

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 2em;
    justify-content: space-between;
    margin: 1em 0;

    h2 {
      font-size: 1em;
    }

    button {
      background: #000;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 1em;
      border-radius: 5px;
      padding: 0.75em 1em;
      border: none;
    }
  }

  > .filters {
    display: flex;
    gap: 1rem;

    label {
      border-radius: 5px;
      border: 1px solid #aaa;
      overflow: hidden;
    }

    input {
      border: none;
      padding: 0.5rem 0.75rem;
    }
  }
`;
