import {
  FIND_ALL_PRODUCTS_WMS,
} from "../Actions/ActionsTypes";

const initialState = {
  products: [],
  file: [],
};

export default function root(state = initialState, action) {
  switch (action.type) {
    case FIND_ALL_PRODUCTS_WMS:
      return {
        ...state,
        products: action.payload.data,
      };
    
    // case FIND_FILE_STOCK:
    //   return {
    //     ...state,
    //     file: action.payload.data,
    //   };
    default:
      return state;
  }
}
