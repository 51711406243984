import React, { createContext, useContext, useState } from "react";
import { BiCheck, BiError, BiInfoCircle } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

const icons = {
  info: BiInfoCircle,
  error: BiError,
  warning: BiError,
  success: BiCheck,
};

const init = { alerts: [], createAlert: () => null };
export const alertsContext = createContext(init);

function AlertsProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const removeAlert = (id) =>
    setAlerts((old) => old.filter((alert) => alert.id !== id));

  /**
   *
   * @param text Texto de la nueva alerta
   * @param code codigo de erorr a mostrar (opcional)
   * @param type success, warning, error, info
   * @param timeout Tiempo en ms si no se le pasa un valor la notificacion no va a desaparecer sola
   * @returns La funcion devuelve el id de la alerta
   *
   */
  const createAlert = (data) => {
    const getAlert = (props) => {
      const { text, type, timeout, code } = props;
      let _type = "info";
      if (["warning", "success", "error"].includes(type)) _type = type;
      const _id = `${new Date().getTime()}${Math.random()}`;
      return { id: _id, type: _type, text, code, timeout };
    };

    if (Array.isArray(data)) {
      const alerts = data.map(getAlert);
      setAlerts((old) => [...old, ...alerts.map(({ timeout, ...a }) => a)]);

      alerts.forEach(
        ({ timeout, id }) =>
          timeout && setTimeout(() => removeAlert(id), timeout)
      );

      return alerts.map((a) => a.id);
    }

    const { timeout, ...alert } = getAlert(data);
    setAlerts((old) => [...old, alert]);
    if (timeout) setTimeout(() => removeAlert(alert.id), timeout);
    return alert.id;
  };

  const value = {
    alerts,
    createAlert,
    removeAlert,
  };
  return (
    <alertsContext.Provider value={value}>
      <Alerts>
        {alerts.map(({ text, type, id, code }) => {
          const Icon = icons[type];
          return (
            <li className={`alert ${type}`}>
              {Icon && <Icon size={18} />}
              <p>
                {code && <span className="code">{code}:</span>} {text}
              </p>
              <button className="close" onClick={() => removeAlert(id)}>
                <FaTimes />
              </button>
            </li>
          );
        })}
      </Alerts>
      {children}
    </alertsContext.Provider>
  );
}

export default AlertsProvider;

const Alerts = styled.ul`
  display: flex;
  flex-flow: column;
  gap: 0.25em;
  position: fixed;
  z-index: 99999;
  bottom: 0.5rem;
  left: 0.5rem;
  list-style: none;

  .alert {
    position: relative;
    color: #fff;
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
    gap: 1em;
    padding: 0.75em 3rem 0.75em 1em;
    min-width: 240px;
    max-width: 320px;

    p {
      width: 220px;
      .code {
      }
    }
    .close {
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 3rem;
      border: none;
    }
  }

  .alert.success {
    background: #57a81e;
  }
  .alert.warning {
    background: #d1831d;
  }
  .alert.error {
    background: #f55;
  }
  .alert.info {
    background: #666;
  }
`;

export function useAlert() {
  return useContext(alertsContext);
}
