import { combineReducers } from "redux";

import ordersReducer from "./Orders";
import productReducer from "./Products";
import authReducer from "./Auth";
import warehouseReducer from "./Warehouses";

const reducers = combineReducers({
	orders: ordersReducer,
	product: productReducer,
	auth: authReducer,
	warehouse: warehouseReducer,
});

export default reducers;
