import styled from "styled-components";
import React, { useId } from "react";
import {
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiChevronUp,
  BiDownload,
} from "react-icons/bi";

import { DownloadButton, TableContainer } from "./Table.styles";

import Loader from "../Loader";

/**
 *
 * onNextPage si se le pasa una funcion muestra el boton de siguiente, si es false no muestra nada
 * onNextPage si se le pasa una funcion muestra el boton de anterior, si es false no muestra nada
 *
 */
function Table({
  className,
  columns,
  current,
  totalPages,
  onClick,
  onNextPage,
  onPrevPage,
  rows,
  download,
  downloadCB = () => {},
  state,
  show,
}) {
  const id = useId();

  const _onClick = (e, i) => {
    if (onClick) onClick(e, i);
  };

  const _download = () => {
    if (downloadCB) {
      show();
      return downloadCB();
    }
    return null;
  };

  const _prev = () => {
    if (typeof onPrevPage === "function") onPrevPage();
  };
  const _next = () => {
    if (typeof onNextPage === "function") onNextPage();
  };

  return !state ? (
    <div>
      <TableTop>
        {download && (
          <DownloadButton type="button" onClick={_download}>
            Descargar <BiDownload size={18} />
          </DownloadButton>
        )}
      </TableTop>

      <TableContainer clickable={!!onClick} className={className}>
        <table>
          <thead>
            <tr>
              {columns.map(({ value, sort, ...props }, i) => (
                <th {...props} key={`${id}-col-${i}`}>
                  {typeof value !== "object" ? (
                    <p>
                      {value}
                      {!sort || sort === 0 ? null : sort === 1 ? (
                        <BiChevronUp size={20} />
                      ) : (
                        sort === -1 && <BiChevronDown size={20} />
                      )}
                    </p>
                  ) : (
                    value
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((cells, i) => (
              <tr onClick={() => _onClick(cells, i)} key={`${id}-row-${i}`}>
                {cells.map(({ value, ...props }, i) => (
                  <td {...props} key={`${id}-value-${i}`}>
                    {typeof value !== "object" ? <p>{value}</p> : value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      <Pagination>
        {!!onPrevPage && (
          <button type="button" onClick={_prev}>
            <BiChevronLeft />
          </button>
        )}
        {!!current && (
          <p className="current">
            {current} de {totalPages}
          </p>
        )}
        {!!onNextPage && (
          <button type="button" onClick={_next}>
            <BiChevronRight />
          </button>
        )}
      </Pagination>
    </div>
  ) : (
    <Loader />
  );
}

export default Table;

const DOWNLOAD_CONFIG = {
  headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
  footers: false, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
  formats: ["xlsx"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
  filename: `table-data`, // (id, String), filename for the downloaded file, (default: 'id')
  bootstrap: false, // (Boolean), style buttons using bootstrap, (default: true)
  exportButtons: false, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
  position: "top", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
  ignoreRows: null, // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
  ignoreCols: null, // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
  trimWhitespace: true, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
  RTL: false, // (Boolean), set direction of the worksheet to right-to-left (default: false)
  sheetname: "id", // (id, String), sheet name for the exported spreadsheet, (default: 'id')
};

const TableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.25em 0;

  select,
  input {
    padding: 0.25em 0.5em 0.25em 0.5em;
    border: 1px solid #555;
    border-radius: 3px;
    background: #fff;
    margin: 0;
  }

  .filters {
    display: flex;
    gap: 1.75em;
    font-size: 0.8rem;

    .filter {
      display: flex;
      align-items: center;
      gap: 0.5em;

      p {
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }

  .submit {
    border: none;
    border-radius: 0;
    background: #000;
    color: #fff;
    padding: 0 1em;
    font-weight: 600;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;

  .current {
    font-size: 0.85rem;
    font-weight: 600;
    transform: translate(0, -2px);
  }

  button {
    display: flex;
    align-items: center;
    gap: 0.25em;
    border: none;
    background: transparent;
    padding: 1em;
    > span {
      transform: translate(0, -2px);
    }
  }
`;
