import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../styles/colors";

/**
 * @param {string} text
 */
function Loader({ text }) {
  return (
    <LoaderContainer>
      <div className="deco">
        <div className="top-left" />
        <div className="bottom-right" />
        <div className="bottom-left" />
      </div>
      <div className="container">
        <Spinner className="spin" />
        <p className="text">{text || "DESCARGANDO ARCHIVO"}</p>
      </div>
    </LoaderContainer>
  );
}

export default Loader;

const LoaderContainer = styled.div`
	width: 50vh;
	margin-left: 80vh;
	height: 50vh;
	display: grid;
	place-items: center;
	background-color: #${colors.hex.primary};

	.deco,
	.bottom-right,
	.top-left,
	.bottom-left {
		position: absolute;
	}

	.deco {
		overflow: hidden;
		width: 100%;
		height: 100%;

		.bottom-right {
			bottom: 0;
			right: 0;
			transform: translate(25%, 25%);
			width: 40%;
			height: 50%;
			outline-offset: 0.75rem;
			outline: 0.5rem solid #fff4;
			border-radius: 5rem 0 0 0;
			border: 1rem solid #fff3;
		}
		.top-left {
			top: 0;
			left: 0;
			transform: translate(-25%, -25%);
			width: 60%;
			height: 50%;

			outline-offset: 0.75rem;
			outline: 0.5rem solid #fff2;
			background: #fff1;
			border-radius: 0 0 5rem 0;
		}
		.bottom-left {
			bottom: 0;
			left: 0;
			transform: translate(-30%, 30%);
			width: 25%;
			aspect-ratio: 1/1;

			background: #0001;
			border-radius: 100%;
			outline: 0.5rem solid #0002;
			outline-offset: 0.5rem;
		}
	}

	.container {
		display: flex;
		flex-flow: column;
		align-items: center;
		gap: 3em;
	}
	.text {
		color: #fff;
		font-weight: 700;
	}
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  to { transform: rotate(360deg); }
`;
const Spinner = styled.span`
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  border: 5px solid #fff;
  border-bottom: 5px solid #0000;
  animation: ${spin} 1.5s linear infinite;
`;
