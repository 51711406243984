import { useCallback, useState } from "react";
import axios from "axios";
import { PORT_API } from "../../../Redux/Actions/ActionsTypes";
import { FaBox, FaTruckRampBox } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import { BiCloudDownload } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { calculateTimeDiff } from "../../../Utils/time";
import { CardContainer, Container, Filters, ListContainer } from "./styles";
import { useDebounce } from "../../../hooks/useDebounce";

export function ListaMetricas() {
  const [metricas, setMetricas] = useState({
    isLoading: true,
    data: [],
  });

  const [inputs, setInputs] = useState({ search: null });

  const cb = useCallback(async ({ search }) => {
    if (metricas.data.length && metricas.isLoading) return null;
    setMetricas((old) => ({ ...old, isLoading: true }));
    const { data } = await axios.get(`${PORT_API}/metrics`, {
      params: { search },
    });

    const _aux = [];

    data.forEach((p) => {
      if (!p || _aux.find((a) => a.nro_pedido === p.nro_pedido)) return;
      const eventos = p.eventos
        ?.map((e) => ({ ...e, Fecha: new Date(e.Fecha) }))
        .sort((e1, e2) => e1.Fecha.getTime() - e2.Fecha.getTime());
      _aux.push({ ...p, eventos });
    });

    setMetricas({ data: _aux, isLoading: false });
  }, []);

  useDebounce(inputs, cb);
  const onChange = ({ target: { name, value } }) =>
    setInputs((old) => ({ ...old, [name]: value }));

  const [downloading, setDownloading] = useState(false);
  const download = async () => {
    setDownloading(true);
    const { data } = await axios.post(
      `${PORT_API}/metrics/download`,
      {},
      { responseType: "arraybuffer" }
    );

    const url = URL.createObjectURL(new Blob([data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = "metricas.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownloading(false);
  };

  return (
    <Container>
      <Filters>
        <div className="groups">
          <div className="group">
            <label htmlFor="search">Buscar pedidos</label>
            <input
              id="search"
              name="search"
              type="text"
              placeholder="Buscar pedido"
              value={inputs.search || ""}
              onChange={onChange}
            />
          </div>
        </div>

        <button
          disabled={downloading}
          onClick={download}
          className="btn download"
        >
          {downloading ? (
            <>Descargando informe...</>
          ) : (
            <>
              Descargar informe <BiCloudDownload size={20} />
            </>
          )}
        </button>
      </Filters>

      {metricas.isLoading ? (
        <p className="loading">Cargando Metricas</p>
      ) : (
        <ListContainer>
          {metricas.data.map((m) => (
            <Card key={`card-${m.nro_pedido}`} pedido={m} />
          ))}
        </ListContainer>
      )}
    </Container>
  );
}

export function Card({ pedido }) {
  return (
    <CardContainer to={`/metricas/${pedido.nro_pedido}`}>
      <h2>{pedido.nro_pedido}</h2>
      <p className="total-time">
        Tiempo transcurrido:{" "}
        {calculateTimeDiff(
          pedido.fecha_ingreso,
          pedido.eventos[pedido.eventos.length - 1].Fecha ||
            pedido.fecha_etiqueta
        )}
      </p>

      <div className="line">
        <div className="data">
          <FaMapMarkerAlt />
          <p className="status">Servicom</p>
        </div>

        {pedido.wms.length > 0 ? (
          <div className="data">
            <FaTruckRampBox />
            <p className="status">WMS</p>
          </div>
        ) : null}

        {pedido.wms.map(
          (w) =>
            w.fecha_wms && (
              <>
                <div className="data">
                  {w.estado_wms === "pendiente" && <IoMdTime />}
                  {w.estado_wms === "preparado" && <FaBox />}
                  {w.estado_wms === "despachado" && <FaTruckRampBox />}
                  <p className="status">WMS {w.estado_wms}</p>
                </div>
              </>
            )
        )}

        <div className="data">
          <FaMapMarkerAlt />
          <p className="status">Andreani</p>
        </div>
      </div>
    </CardContainer>
  );
}
