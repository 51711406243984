import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Table from "../../Table";
import SearchBar from "../../SearchBar/SearchBar";
import usePagination from "../../../hooks/usePagination";

import { filterWarehouse } from "../../../Redux/Actions/Warehouse";

import useShow from "../../../hooks/useShow";
import { StockDownload } from "../../StockDownload";

const columns = [
  { value: "Código" },
  { value: "Familia" },
  { value: "Modelo" },
  { value: "Origen" },
  { value: "Variante" },
  { value: "Gama" },
  { value: "Tipologia" },
  { value: "Stand By" },
  { value: "Retail" },
  { value: "Ecommerce" },
  { value: "Depósito 26" },
  { value: "18-Loyalty" },
  { value: "SERV" },
  { value: "Depósito GLSB" },
  { value: "SERV vs GLSB" },
];

export default function TableStock() {
  const dispatch = useDispatch();
  const { stocks, length, systems } = useSelector((state) => state.warehouse);

  const [_current, _setCurrent] = useState(0);

  const [{ cood }, _setCood] = useState("");

  const index = usePagination(length);

  const { state, show, toggle } = useShow({ init: false });

  const stocksByCood = (cood) => {
    _setCood((old) => ({ ...old, cood }));
    _setCurrent(0);
    dispatch(filterWarehouse({ cood, start: 0, finish: 20 }));
  };

  useEffect(() => {
    dispatch(filterWarehouse({ ...index[0] }));
  }, [dispatch]);

  const _onNextPage = () => {
    _setCurrent((old) => (old + 1 < index.length ? old + 1 : old));
    if (cood && _current + 1 < index.length) {
      dispatch(filterWarehouse(cood, { ...index[_current + 1] }));
    } else if (_current + 1 < index.length)
      dispatch(filterWarehouse({ ...index[_current + 1] }));
  };

  const _onPrevPage = () => {
    _setCurrent((old) => (old - 1 >= 0 ? old - 1 : old));
    if (cood && _current - 1 >= 0) {
      dispatch(filterWarehouse(cood, { ...index[_current - 1] }));
    } else if (_current - 1 >= 0)
      dispatch(filterWarehouse({ ...index[_current - 1] }));
  };

  return (
    <TableContainer>
      <div className="header">
        <h2>CRUCE LOGICO</h2>

        <div className="right">
          <SearchBar className={"searchbar"} stocksByCood={stocksByCood} />
          <StockDownload />
        </div>
      </div>

      <div className="bottom">
        <p className={`tango ${systems.tango ? "active" : "inactive"}`}>
          Tango
        </p>
        <p className={`wms ${systems.wms ? "active" : "inactive"}`}>WMS</p>
      </div>

      <Table
        current={(_current + 1).toString()}
        totalPages={index.length ? index.length : 1}
        columns={columns}
        download={false}
        state={state}
        show={show}
        rows={
          stocks[0]
            ? stocks.map((e) => [
                { value: `${e.cood}` },
                { value: `${e.family}` },
                { value: `${e.model}` },
                { value: `${e.origin}` },
                { value: `${e.variant}` },
                { value: `${e.Gama}` },
                { value: `${e.typography}` },
                { value: `${e.warehouse20}` },
                { value: `${e.warehouse21}` },
                { value: `${e.warehouse22}` },
                { value: `${e.warehouse26 ?? 0}` },
                { value: `${e.warehouse18 ?? 0}` },
                {
                  value: `${
                    e.warehouse20 +
                    e.warehouse21 +
                    e.warehouse22 +
                    e.warehouse26
                  }`,
                },
                { value: `${e.warehouseWms21 ?? 0}` },
                {
                  value: `${
                    e.warehouse20 +
                    e.warehouse21 +
                    e.warehouse22 +
                    e.warehouse26 -
                    (e.warehouseWms21 ?? 0)
                  }`,
                },
              ])
            : []
        }
        onNextPage={_onNextPage}
        onPrevPage={_onPrevPage}
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  position: relative;
  width: 95%;
  margin: 0 auto;
  font-size: 0.85em;

  > .bottom {
    display: flex;
    gap: 1em;

    .tango,
    .wms {
      padding: 0.75em 1em;
      border-radius: 5px;
      font-size: 0.6rem;
      font-weight: bold;
    }
    .tango.inactive,
    .wms.inactive {
      background: #f22;
      color: #fff;
    }
    .tango.active,
    .wms.active {
      background: #1b1;
      color: #fff;
    }
  }

  .empty {
    width: 12em;
    text-align: center;
    font-size: 2em;
    margin: 5em auto;
    font-weight: 600;
    opacity: 0.25;
  }

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 2em;
    justify-content: space-between;
    margin: 1em 0;

    h2 {
      font-size: 1em;
    }

    .right {
      display: flex;
      gap: 1em;
      align-items: center;
    }
    .searchbar {
      display: flex;
      align-items: center;
      gap: 0.5em;
      border: 1px solid #888;
      padding: 0.25em 0.5em;
      background: #fafafa;
      border-radius: 5px;

      .icon-container {
        display: grid;
        place-items: center;
        width: 2rem;
        height: 2rem;
        background: transparent;
      }
      input {
        outline: none;
        border: none;
        padding: 0;
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }
  }
`;
