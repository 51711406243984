import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import colors from "../../styles/colors";

function PopUp({ full, children, center, onClick }) {
  useEffect(() => {
    document.documentElement.style.maxHeight = "100vh";
    document.documentElement.style.overflow = "hidden";
    return () => {
      document.documentElement.style.maxHeight = "max-content";
      document.documentElement.style.overflow = "auto";
    };
  }, []);

  return (
    <PopUpContainer full={full} center={center}>
      <div className="overlay" onClick={onClick} />
      <div className="content" onClick={full ? onClick : () => {}}>
        {children}
      </div>
    </PopUpContainer>
  );
}

export default PopUp;

const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000a;
  }

  > .content {
    overflow-y: auto;
    background-color: #fff;
    width: 90%;
    max-width: 480px;
    margin: 1em auto;
    max-height: calc(100vh - 2em);
    position: relative;

    scrollbar-width: thin;
    scrollbar-color: #${colors.hex.primary} #fff;
  }

  ${({ center }) =>
    center &&
    css`
      display: grid;
      place-items: center;
    `}

  ${({ full }) =>
    full &&
    css`
      > .content {
        width: calc(100vw - 2em);
        max-width: 1024px;
      }
    `}
`;
