/**
 *
 * Link {
 *  to: string;
 *  children?: ReactNode | ReactNode[];
 *  icon?: ReactNode | ReactNode[];
 *  label?: string;
 *  title?: string;
 *  show: "always" | "authenticated" | "unauthenticated" | null;
 * }
 *
 */

const links_landing = {
  left: [],
  middle: [],
  right: [],
};

const links = {
  landing: links_landing,
};

export default links;
