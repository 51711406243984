import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PedidosAndreani } from "../../Components/andreani";

export function Andreani() {
  const navigate = useNavigate();
  const session = useSelector((store) => store.auth);

  useEffect(() => {
    if (!session) navigate("/");
  }, [session]);

  return (
    <Container>
      <PedidosAndreani />
    </Container>
  );
}

export const Container = styled.div`
  padding: 0 0 0 var(--navbar-size);
  min-height: 100vh;
`;
