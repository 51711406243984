import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  padding: 0 0 0 var(--navbar-size);
  min-height: 100vh;

  .detalle {
    .totals {
      display: flex;
      gap: 1rem;
      font-weight: 600;
    }
  }

  .error {
    color: #f55;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }

  .content {
    width: max-content;
    max-width: 480px;
    padding: 0.75rem 1rem;
    text-align: center;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .send {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.75rem 1rem;
      border-radius: 5px;
      border: none;
      background: #${colors.hex.primary};
      color: #fff;
    }
  }

  > .container {
    width: 90%;
    min-width: 320px;
    margin: 2rem auto;
    padding: 0 0 0 var(--navbar-size);
  }

  h1 {
    font-size: 1.25rem;
  }

  section {
    margin: 2rem 0 0 0;

    .section-header {
      display: flex;
      gap: 1rem;
      border-bottom: 1px solid #${colors.hex.primary};
    }

    .section-btn {
      font-size: 1rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      border-radius: 5px 5px 0 0;
      width: max-content;
      background: #f0f0f0;
      color: #666;
      border: 1px solid #ddd;
      border-bottom: none;
    }

    .section-btn.active {
      color: #fff;
      background: #${colors.hex.primary};
      border: 1px solid #${colors.hex.primary_400};
      border-bottom: none;
    }
  }
`;
