import { BiPencil } from "react-icons/bi";
import styled from "styled-components";
function getDir(str) {
  return str
    .trim()
    .split(" ")
    .filter((n) => n !== "0")
    .join(" ")
    ?.toLowerCase();
}

function printDate(str) {
  const date = new Date(str);
  return date.toLocaleString();
}

export function Principal({ details, onChange, inputs }) {
  return (
    <Container>
      <div className="groups">
        <div className="group">
          <span className="label">Fecha</span>
          <p className="value">
            {details?.fecha_pedido
              ? printDate(details.fecha_pedido).split(",")[0]
              : "-"}
          </p>
        </div>

        <div className="group">
          <span className="label">Razon Social</span>
          <p className="value">{details?.razon_social || "-"}</p>
        </div>

        <div className="group">
          <span className="label">Transporte</span>
          <p className="value">{details?.transporte || "-"}</p>
        </div>

        <div className="group">
          <span className="label">Vendedor</span>
          <p className="value">{details?.vendedor || "-"}</p>
        </div>

        <div className="group">
          <span className="label">Nro. Remito</span>
          <p className="value">{details?.nro_remito || "-"}</p>
        </div>

        <div className="group">
          <span className="label">Direccion</span>
          {details?.wasSent && !details.etiqueta ? (
            <label className="value edit">
              <span>
                <BiPencil />
              </span>
              <input
                onChange={onChange}
                name="direccion"
                value={inputs.direccion}
                placeholder={getDir(details?.direccion || "")}
              />
            </label>
          ) : (
            <p className="value cap">
              {getDir(details?.direccion || "") || "-"}
            </p>
          )}
        </div>

        <div className="group">
          <span className="label">Localidad</span>

          {details?.wasSent && !details.etiqueta ? (
            <label className="value edit">
              <span>
                <BiPencil />
              </span>
              <input
                onChange={onChange}
                name="localidad"
                value={inputs.localidad}
                placeholder={details?.localidad || "-"}
              />
            </label>
          ) : (
            <p className="value cap">
              {details?.localidad?.toLowerCase() || "-"}
            </p>
          )}
        </div>

        <div className="group">
          <span className="label">Provincia</span>
          {details?.wasSent && !details.etiqueta ? (
            <label className="value edit">
              <span>
                <BiPencil />
              </span>
              <input
                onChange={onChange}
                name="provincia"
                value={inputs.provincia}
                placeholder={details?.provincia || "-"}
              />
            </label>
          ) : (
            <p className="value cap">{details?.provincia || "-"}</p>
          )}
        </div>

        <div className="group">
          <span className="label">Cod. Postal</span>
          {details?.wasSent && !details.etiqueta ? (
            <label className="value edit">
              <span>
                <BiPencil />
              </span>
              <input
                onChange={onChange}
                name="cod_postal"
                value={inputs.cod_postal}
                placeholder={details?.cod_postal || "-"}
              />
            </label>
          ) : (
            <p className="value">{details?.cod_postal || "-"}</p>
          )}
        </div>

        <div className="group">
          <span className="label">Telefono</span>
          <p className="value">{details?.telefono || "-"}</p>
        </div>
      </div>
    </Container>
  );
}

export const Container = styled.div`
  .cap {
    text-transform: capitalize;
  }

  .groups {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  .group {
    display: flex;
    flex-flow: column;
    gap: 0.25rem;

    .label {
      font-size: 1rem;
    }
    .value {
      padding: 0.75rem 1rem;
      background: #f0f0f0;
      border-radius: 5px;
    }
    .value.edit {
      border: 1px solid #ddd;
      font-size: 1rem;
      background: #ffff;
      display: flex;
      gap: 1rem;

      input {
        padding: 0;
        width: 100%;
        border: none;
        outline: none;
      }

      .icon {
        width: 1rem;
        height: 1rem;
        transform: translate(0, 2px);
        color: #aaa;
      }
    }
  }
`;
