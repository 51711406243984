import styled from "styled-components";
import colors from "../../../styles/colors";

export const TableContainer = styled.div`
  width: 95%;
  margin: 0 auto;

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 2em;
    margin: 1em 0;

    h2 {
      font-size: 1em;
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;

  .btn {
  }

  .btn.download {
    color: #fff;
    padding: 0 1rem;
    border-radius: 5px;
    border: none;
    background: #${colors.hex.primary};
  }

  .btn:disabled {
    color: #444;
    background: #f0f0f0;
    cursor: default;
  }

  input {
    padding: 0.75rem 1rem;
    outline: none;
    border: none;
    background-color: #fafafa;
    border-radius: 5px;
    width: max-content;
    min-width: 15rem;
  }

  input:focus {
    outline: 1px solid #${colors.hex.primary};
  }

  label {
    display: flex;
    width: max-content;
  }
`;
