import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { IconContext } from "react-icons";
import { TbArrowsCross } from "react-icons/tb";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { IoSend, IoPrintSharp } from "react-icons/io5";
import { FaChartLine, FaTruckRampBox } from "react-icons/fa6";
import { FaShippingFast } from "react-icons/fa";

import styled, { css } from "styled-components";

import Layout from "./Components/Layout";
import AlertsProvider from "./context/alerts";
import Login from "./Pages/Auth/Login";
import PedidoDetails from "./Pages/pedido_details";
import NavBarToolsCard from "./Components/Navbar/NavBarTools/NavBarToolsCard";

import "./App.css";

import { Pedidos } from "./Pages/pedidos";
import { Enviados } from "./Pages/enviados";
import Upload from "./Components/WMS/Upload";
import { Stock } from "./Pages/stock";
import { Andreani } from "./Pages/andreani";
import { Etiquetas } from "./Pages/etiquetas";
import Metrics from "./Pages/metrics";
import Informes from "./Pages/informes";
import { useAuth } from "./hooks/useAuth";
import { Warehouse23 } from "./Pages/warehouse23";

export const sections = [
  {
    category: "Conciliación de Stock",
    links: [
      {
        role: "cruce-logico",
        Component: Stock,
        link: "/stock",
        label: "Cruce Logico",
        icon: TbArrowsCross,
      },
      {
        role: "cruce-logico",
        Component: Warehouse23,
        link: "/deposito-23",
        label: "Depo. 23",
        icon: TbArrowsCross,
      },
      {
        role: "archivo-cruce-logico",
        Component: Upload,
        link: "/upload",
        label: "Carga manual stock",
        icon: AiOutlineCloudUpload,
      },
    ],
  },
  {
    category: "gestion logistica",
    links: [
      {
        role: "pedidos-sin-enviar",
        link: "/pedidos",
        Component: Pedidos,
        label: "Enviar a WMS",
        icon: IoSend,
      },
      {
        role: "pedidos-enviados-wms",
        link: "/enviados",
        Component: Enviados,
        label: "Pedidos enviados",
        icon: FaTruckRampBox,
      },
    ],
  },
  {
    category: "Andreani",
    links: [
      {
        role: "seguimiento-andreani",
        Component: Andreani,
        link: "/seguimiento-andreani",
        label: "Seguimiento andreani",
        icon: FaShippingFast,
      },
      {
        role: "etiquetas",
        Component: Etiquetas,
        link: "/etiquetas",
        label: "Impresion de etiquetas",
        icon: IoPrintSharp,
      },
    ],
  },
  {
    category: "Informes",
    links: [
      {
        role: "metricas",
        Component: Informes,
        link: "/metricas",
        label: "Metricas pedidos",
        icon: FaChartLine,
      },
    ],
  },
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <IconContext.Provider value={{ className: "icon" }}>
      <AlertsProvider>
        <Layout>
          {!!user && (
            <>
              <div className="left">
                <NavBarToolsCard
                  links={sections}
                  roles={user.rol_logistica?.roles || []}
                  onClick={(to) => navigate(to)}
                  active={location.pathname}
                />
              </div>
            </>
          )}

          <Routes>
            <Route exact path="/" element={<Login />} />
            {!!user && (
              <>
                {sections.map((s) =>
                  s.category
                    ? s.links.map(
                        (l) =>
                          user.rol_logistica?.roles?.includes(l.role) && (
                            <Route path={l.link} element={<l.Component />} />
                          )
                      )
                    : s.links.map(
                        (l) =>
                          user.rol_logistica?.roles?.includes(l.role) && (
                            <Route path={l.link} element={<l.Component />} />
                          )
                      )
                )}

                {user.rol_logistica?.roles?.includes("detalle-pedido") && (
                  <Route path="/pedido/:id" element={<PedidoDetails />} />
                )}
                {user.rol_logistica?.roles?.includes("metrica-pedido") && (
                  <Route path="/metricas/:id" element={<Metrics />} />
                )}
              </>
            )}
          </Routes>
        </Layout>
      </AlertsProvider>
    </IconContext.Provider>
  );
}

export default App;

export const Container = styled.div`
  ${({ isLanding }) =>
    isLanding
      ? css`
          padding: 0;
        `
      : css`
          padding: 0 0 0 var(--navbar-size);
        `}
`;
