import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { PORT_API, PUBLIC_API } from "../../../Redux/Actions/ActionsTypes";
import Table from "../../Table";
import { TableContainer } from "./styles";

const COLUMNS = [
  { value: "Numero de Pedido Servicom" },
  { value: "Codigo de Cliente" },
  { value: "Estado" }, // este dice si ya fue impresa o no
  { value: "" }, // impresion masiva
];
export function EtiquetasTable() {
  const [etiquetas, setEtiquetas] = useState({
    isLoading: false,
    data: [],
    error: null,
  });

  useEffect(() => {
    const p = async () => {
      try {
        setEtiquetas({ isLoading: true, error: null, data: [] });
        const { data } = await axios.get(`${PORT_API}/andreani/etiquetas`);
        setEtiquetas({ isLoading: false, error: null, data: data });
      } catch (e) {
        setEtiquetas((old) => ({ ...old, isLoading: false, error: e }));
      }
    };
    p();
  }, []);

  const printPDF = async (e) => {
    const url = `${PUBLIC_API}/etiquetas/${e.file}`;
    const { data } = await axios.get(url, { responseType: "blob" });

    const url2 = URL.createObjectURL(data);

    const iframe = document.createElement("iframe");

    iframe.style.position = "absolute";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.width = "0";
    iframe.style.heigth = "0";
    iframe.style.opacity = "0";

    iframe.src = url2;
    iframe.onload = async function () {
      const { data } = await axios.put(
        `${PORT_API}/andreani/etiqueta/${e.nro_pedido}`,
        {
          printed: true,
        }
      );

      if (data.data) {
        setEtiquetas((old) => ({
          ...old,
          data: old.data.map((_e) =>
            _e.nro_pedido === e.nro_pedido
              ? {
                  ..._e,
                  status: data.data.status,
                }
              : _e
          ),
        }));
      }

      iframe.contentWindow.print();
    };

    document.body.appendChild(iframe);
  };

  const [q, setQ] = useState({
    query: null,
    status: null,
  });

  return (
    <TableContainer>
      <div className="header">
        <h2>Etiquetas Andreani</h2>
      </div>

      <div className="filters">
        <input
          name="query"
          className=""
          placeholder="Buscar por nro. de pedido o cod. cliente"
          onChange={({ target: { value } }) =>
            setQ((old) => ({ ...old, query: value ? value : null }))
          }
        />
        <ReactSelect
          options={[
            { label: "Todas", value: null },
            { label: "No impresa", value: false },
            { label: "Impresa", value: true },
          ]}
          onChange={(l) => setQ((old) => ({ ...old, status: l.value }))}
        />
      </div>

      <Table
        columns={COLUMNS}
        rows={etiquetas.data
          .filter((e) => {
            if (q.query === null && q.status === null) return true;

            const show = [false, false];

            if (q.query) {
              const r = new RegExp(q.query);
              show[0] = r.test(e.nro_pedido) || r.test(e.client.codigo);
            }

            if (typeof q.status === "boolean") {
              if (q.status) show[1] = e.status === "printed";
              else show[1] = e.status !== "printed";
            } else {
              show[1] = true;
            }

            return show.every((s) => s === true);
          })
          .map((e) => [
            {
              value: (
                <Link to={`/metricas/${nroPedido(e.nro_pedido)}`}>
                  {nroPedido(e.nro_pedido)}
                </Link>
              ),
            },
            { value: e.client.codigo },
            { value: e.status === "printed" ? "impresa" : "no impresa" },
            { value: <button onClick={() => printPDF(e)}>Imprimir</button> },
          ])}
      />
    </TableContainer>
  );
}

const nroPedido = (v) => v.toString().padStart(13, "0");
