import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { PORT_API } from "../../../Redux/Actions/ActionsTypes";
import Table from "../../Table";
import { FiltersContainer, TableContainer } from "./styles";

export function PedidosAndreani() {
  const session = useSelector((store) => store.auth);

  const [pedidos, setPedidos] = useState({
    isLoading: false,
    data: [],
    error: null,
  });

  const cb = useCallback(async () => {
    try {
      setPedidos((old) => ({ ...old, error: null, isLoading: true }));
      const { data } = await axios.get(`${PORT_API}/andreani/orders`, {
        params: { email: session.email },
      });
      const _aux = data.map((p) => {
        const _aux_eventos = new Map();
        p.eventos.forEach((e) => {
          _aux_eventos.set(e.EstadoId, {
            ...e,
            Fecha: new Date(e.Fecha),
          });
        });

        const _aux_p = {
          ...p,
          eventos: [..._aux_eventos.values()].sort(
            (a, b) => b.Fecha.getTime() - a.Fecha.getTime()
          ),
        };

        return _aux_p;
      });
      setPedidos({ data: _aux, error: null, isLoading: false });
    } catch (e) {
      setPedidos((old) => ({
        ...old,
        isLoading: false,
        error: e?.response ? e.response.data : e.message,
      }));
    }
  }, []);

  const send = async (nro_pedido) => {
    const { data } = await axios.post(
      `${PORT_API}/andreani/enviar/${nro_pedido}`
    );
  };

  useEffect(() => {
    cb();
  }, [cb]);

  const [inputs, setInputs] = useState({
    query: null,
  });

  const onChange = ({ target: { name, value } }) => {
    setInputs((old) => ({ ...old, [name]: value }));
  };

  const user = useAuth();

  const columns = [
    { value: "Numero de Pedido Servicom" },
    { value: "Remito" },
    { value: "Numero de Envio Andreani" },
    { value: "Razon Social" },
    { value: "Numero Tracking" },
    { value: "KV" },
    { value: "Importe GASTOFLETECOM" },
    { value: "Estado de envio" },
    ...(user.rol_logistica?.roles.includes("etiquetas")
      ? [{ value: "Enviar" }]
      : []),
  ];

  const addZero = (v) => v.toString().padStart(2, "0");
  const [range, setRange] = useState({
    init: null,
    end: null,
    min: null,
    max: null,
  });

  const [status, setStatus] = useState({ isLoading: false });
  const download = async () => {
    if (!range.init || !range.end) return null;

    try {
      setStatus({ isLoading: true });
      const { data } = await axios.post(
        `${PORT_API}/andreani/download-tracking`,
        { range: [range.init, range.end], email: session.email },
        { responseType: "arraybuffer" }
      );

      const url = URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "seguimiento.xlsx";

      a.click();
      setStatus({ isLoading: false });
    } catch (e) {
      setStatus({ isLoading: false });
    }
  };

  return (
    <TableContainer>
      <div className="header">
        <h2>Seguimiento andreani</h2>
      </div>

      <FiltersContainer>
        <label>
          <input
            name="query"
            placeholder="Buscar por nro. de pedido o nro. envio"
            value={inputs.query || ""}
            onChange={onChange}
          />
        </label>

        <label>
          <input
            type="date"
            onChange={({ target: { value } }) => {
              const min = new Date(value);
              min.setDate(min.getDate() + 1);
              const max = new Date(value);
              max.setDate(max.getDate() + 15);

              const _min = `${min.getFullYear()}-${addZero(
                min.getMonth() + 1
              )}-${addZero(min.getDate())}`;

              const _max = `${max.getFullYear()}-${addZero(
                max.getMonth() + 1
              )}-${addZero(max.getDate())}`;

              let _end = null;

              console.log(range.end);
              if (range.end) {
                const _aux = new Date(range.end).getTime();
                if (_aux <= min.getTime()) _end = _min;
                else if (_aux >= max.getTime()) _end = _max;
                else _end = range.end;
              }

              setRange((old) => ({
                ...old,
                init: value,
                end: _end,
                min: _min,
                max: _max,
              }));
            }}
          />
        </label>

        <label>
          <input
            type="date"
            min={range.min}
            max={range.max}
            value={range.end}
            onChange={({ target: { value } }) =>
              setRange((old) => ({ ...old, end: value }))
            }
          />
        </label>
        <button
          type="button"
          onClick={download}
          disabled={status.isLoading || !range.init || !range.end}
          className="btn download"
        >
          {status.isLoading
            ? "Descargando..."
            : range.init && range.end
            ? "Descargar"
            : "Seleccione rango para descarga."}
        </button>
      </FiltersContainer>

      <Table
        columns={columns}
        rows={pedidos.data
          .filter((p) => {
            if (inputs.query) {
              const r = new RegExp(inputs.query);
              return r.test(p.nro_pedido) || r.test(p.numero_envio);
            }

            return true;
          })
          .map((p) => [
            {
              value: (
                <Link
                  to={`/pedido/${p.nro_pedido.toString().padStart(13, "0")}`}
                >
                  {p.nro_pedido.toString().padStart(13, "0")}
                </Link>
              ),
            },
            { value: p.nro_remito },
            {
              value: (
                <a
                  href={`https://www.andreani.com/envio/${p.numero_envio}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {p.numero_envio}
                </a>
              ),
            },
            { value: p.razon_social },

            { value: p.tracking || p.numero_envio },
            {
              value: p.pedidos
                .reduce((acc, curr) => acc + curr.vol_articulo, 0)
                .toFixed(2),
            },
            {
              value: p.pedidos.find((p) => p.cod_articulo === "GASTOFLETECOM")
                ? p.pedidos.find((p) => p.cod_articulo === "GASTOFLETECOM")
                    .importe
                : "-",
            },
            { value: p.eventos?.[0]?.Estado },
            ...(user.rol_logistica?.roles.includes("etiquetas")
              ? [{ value: <button onClick={send}>Enviar</button> }]
              : []),
          ])}
      />
    </TableContainer>
  );
}
