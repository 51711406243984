import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { Logo, Nav } from "./Navbar.styles";
import links from "./links";
import Avatar from "../Avatar";

export default function Navbar() {
  const session = useSelector((store) => store.auth);

  return (
    <Nav>
      <div className="left">
        <Logo to={`${session ? "/" : "/auth/login"}`}>
          <img
            src="https://servi-compras.com.ar/img/logoServiComprasHeader.svg"
            alt=""
          />
        </Logo>
      </div>

      <div className="middle">
        {links.landing.middle.map(
          (link) =>
            link.role.includes(session.role) && (
              <NavbarLink link={link} className="link" session={!!session} />
            )
        )}
      </div>

      <div className="right">{session && <Avatar />}</div>
    </Nav>
  );
}

function NavbarLink({ className, role, link, session }) {
  const Icon = link.icon || null;
  const L = (
    <NavLink className={className} to={link.to}>
      {Icon && <Icon />}
      {link.children || link.label}
    </NavLink>
  );
  if (!link.show) return null;
  if (link.show === "authenticated" && session) return L;
  if (link.show === "unauthenticated" && !!session) return L;
  return null;
}
