import { BiSearch } from "react-icons/bi";
import React, { useEffect, useState } from "react";

export default function SearchBar({ stocksByCood, className }) {
  const [value, setValue] = useState(null);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  useEffect(() => {
    if (value === null) return;
    const timeout = setTimeout(() => stocksByCood(value), 250);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <label className={className}>
      <div className="icon-container">
        <BiSearch />
      </div>
      <input
        onChange={handleChange}
        value={value}
        placeholder="Buscar por código... "
      />
    </label>
  );
}
