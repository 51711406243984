import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import TableOrders from "../../Components/WMS/Order/TableOrders";
import styled from "styled-components";

export function Pedidos() {
  const navigate = useNavigate();
  const session = useSelector((store) => store.auth);

  useEffect(() => {
    if (!session) navigate("/");
  }, [session]);

  return (
    <Container>
      <TableOrders />
    </Container>
  );
}

export const Container = styled.div`
  padding: 0 0 0 var(--navbar-size);
  min-height: 100vh;
`;
