import React from "react";

import Image from "../../images/login_img.jpeg";
import LoginCard from "../../Components/Auth/Login/LoginCard";
import { AuthContainer } from "../../styles/Auth.styles";

export default function Login() {
  return (
    <AuthContainer>
      <div className="left">
        <img src={Image} alt="" />
      </div>
      <div className="right">
        <LoginCard />
      </div>
    </AuthContainer>
  );
}
