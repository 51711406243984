import styled, { css } from "styled-components";
import colors from "../../styles/colors";

export const TableContainer = styled.div`
  overflow-x: auto;
  border-radius: 5px;
  width: 100%;
  max-width: 90vw;
  table {
    border-collapse: collapse;
    width: 100%;
    background: #f0f0f0;
    thead {
      background: #${colors.hex.primary};
      color: #fff;
    }
    th,
    td {
      text-align: left;
      > p {
        width: max-content !important;
        white-space: no-wrap !important;
      }
    }
    th,
    td {
      padding: 0.5em 1em;
    }
    tbody {
      tr:nth-child(2n) {
        background: #0001;
      }
      tr:hover {
        background: #${colors.hex.primary_400};
        color: #fff;
      }
    }
  }
  ${({ clickable }) =>
    clickable &&
    css`
      table {
        tr {
          cursor: pointer;
        }
      }
    `}
`;

export const DownloadButton = styled.button`
  text-decoration: underline;
  background: transparent;
  border: none;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5em;
  width: max-content;
  margin: 0 0 0 auto;
  padding: 0.5em 0 0.5em 1em;
  font-weight: bold;
`;
