import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../styles/colors";

export const Container = styled.div`
  padding: 1rem;
  .loading {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    color: #aaa;
    margin: 3rem 0 0 0;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem 0;

  .groups {
    display: flex;
    align-items: center;
    gap: 1rem;

    .group {
      display: flex;
      flex-flow: column;

      label {
        font-size: 0.75rem;
        font-weight: 600;
      }
      input {
        padding: 0.5rem 0.75rem;
        border-radius: 5px;
        border: 1px solid #aaa;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    gap: 0.75rem;
  }
  .btn.download {
    background: #${colors.hex.primary};
    font-weight: 600;
    color: #fff;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 3rem;
`;

export const CardContainer = styled(Link)`
  box-shadow: 0 1px 10px #0002;
  border-radius: 5px;
  padding: 2rem;
  text-decoration: none;

  h2 {
    font-size: 0.9rem;
    color: #fff;
    margin: 0 0 1rem 0;
    background: #${colors.hex.primary_400};
    width: max-content;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }

  .total-time {
    margin: 0 0 2rem 0;
    font-size: 0.9rem;
  }

  .line {
    display: flex;
    gap: 1rem;

    .icon {
      color: #${colors.hex.primary}88;
    }

    .data {
      display: flex;
      gap: 0.5rem;
      position: relative;
      text-align: center;
      color: #aaa;

      .status {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 0.9rem;
      }

      :not(:last-child) .status::after {
        content: "";
        display: block;
        background: #0003;
        position: relative;
        width: 1.5rem;
        height: 1px;
      }
    }
  }
`;
