import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { PORT_API } from "../../../Redux/Actions/ActionsTypes";
import { BiCheck } from "react-icons/bi";

const STATUS = ["pendiente", "preparado", "despachado", "anulado"];

export function WMS({ id }) {
  const [status, setStatues] = useState({ isLoading: true, data: [] });

  useEffect(() => {
    const p = async () => {
      setStatues({ isLoading: true, data: [] });
      const { data } = await axios.get(`${PORT_API}/pedidos/status/${id}`);
      setStatues({ isLoading: false, data });
    };
    p();
  }, []);

  const getStatus = (_s) => {
    const _exists = status.data.findIndex(({ status: s }) => _s === s);

    if (_exists === -1) return null;
    return new Date(status.data[_exists].created_at).toLocaleString();
  };

  return (
    <Container>
      <h1>WMS</h1>

      <ul className="status-list">
        {STATUS.map((s) => (
          <li
            className={`status ${getStatus(s) ? "active" : ""}`}
            key={`item-${s}`}
          >
            <div className="icon">
              {getStatus(s) ? <BiCheck size={20} /> : null}
            </div>
            <p className="label">{s}</p>
            <p className="date">{getStatus(s) || "-"}</p>
          </li>
        ))}
      </ul>
    </Container>
  );
}

export const Container = styled.div`
  .status-list {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    list-style: none;
  }

  .label {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
  }

  .date {
    font-size: 1rem;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .icon {
    --size: 1.5rem;
    height: var(--size);
    width: var(--size);
    display: grid;
    place-items: center;
    background: #f0f0f0;
    color: #aaa;
    border-radius: 5px;
  }

  .status.active {
    .label {
      color: rgba(126, 182, 40);
    }

    .icon {
      background: rgba(126, 182, 40);
      color: #fff;
    }
  }
`;
