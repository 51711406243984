import { useState } from "react";

function useShow({ init = false }) {
  const [state, setState] = useState(init);

  const show = () => setState(true);
  const hide = () => setState(false);
  const toggle = () => setState((old) => !old);

  return { state, show, hide, toggle };
}

export default useShow;
