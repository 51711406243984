import styled from "styled-components";
import colors from "../../../styles/colors";

export const DetailsContainer = styled.div`
  padding: 0 0 0 var(--navbar-size);
  min-height: 100vh;

  max-width: 480px;
  margin: 0 auto;

  > .bottom {
    padding: 1em;
  }

  .submit-insurance {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #${colors.hex.primary};
    padding: 0.5em;
    width: 100%;
    color: #ffff;
    border: none;
  }

  > .status {
    position: relative;
    overflow: hidden;
    font-size: 0.95rem;
    border-radius: 0;
    padding: 1.5rem;
    box-shadow: inset 0 -10px 10px -10px #000a;

    ::after,
    ::before {
      content: "";
      position: absolute;
    }

    ::before {
      top: 0;
      left: 0;
      width: 20rem;
      height: 10rem;
      transform: translate(-50%, -50%) rotate(-35deg);
      background-color: #fff2;
      outline: 2px solid #fff2;
      outline-offset: 5px;
    }
    ::after {
      top: bottom;
      right: 0;
      transform: translate(50%, 0) rotate(-35deg);

      width: 10rem;
      height: 10rem;
      background-color: #fff2;
      outline: 2px solid #fff2;
      outline-offset: 5px;
    }
  }

  > .container {
    display: flex;
    flex-flow: column;
    gap: 1em;
    padding: 1em;
    width: 100%;
  }

  .userDate {
    font-size: 0.9rem;
  }

  .images-container {
    overflow-x: auto;
    scrollbar-width: thin;
    padding: 0.5em 0;
  }

  .images {
    display: flex;
    flex-flow: row;
    width: max-content;

    a {
      position: relative;
      display: block;
      width: 100%;

      .overlay {
        display: grid;
        place-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.15s;
        background: #000a;
        color: #fff;
      }

      img {
        max-width: 7em;
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
      }

      :hover {
        .overlay {
          opacity: 1;
        }
      }
    }
  }
`;

export const GroupContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 0.25em;
  padding-bottom: 1em;
  width: 100%;

  ::before {
    content: "";
    position: absolute;
    transform: translate(-50%, 50%);
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 1px;
    background: #${colors.hex.primary}aa;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left,
  .right {
    width: max-content !important;
    min-width: max-content !important;
  }

  .left {
    font-size: 0.9em;
    text-transform: capitalize;
  }

  .separation {
    display: block;
    width: 100%;
    height: 1px;
    background: #0002;
    margin: 0 1em;
  }

  .right {
    input {
      width: 6rem;
      margin: 0 0.25em;
      padding: 0.25em 0.25em 0.25em 0.5em;
      border: 1px solid #0003;
      border-radius: 5px;
      font-size: 0.8rem;
    }
  }
`;

export const UpdateButton = styled.button`
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  border: none;
  border-radius: 0;
  background: #000;
  color: #fff;
`;

export const StatusButtons = styled.div`
  display: flex;
  gap: 1em;
  position: relative;

  button {
    width: 100%;
    font-weight: 600;
    border: none;
    padding: 1em;
    color: #fff;
    border-radius: 5px;
    outline-offset: 2px;
    transition: all 0.25s;
  }

  .aprove {
    background-color: #${colors.hex.primary};
    box-shadow: 0 5px 10px #${colors.hex.primary}00;
    outline: 2px solid #${colors.hex.primary}00;

    :hover {
      outline: 2px solid #${colors.hex.primary}ff;
      box-shadow: 0 5px 10px #${colors.hex.primary}88;
    }
  }

  .reject-container {
    width: 100%;

    .textarea {
      position: absolute;
      bottom: 100%;
      right: 0;
      transform: translate(0, -1rem);
      width: 100%;
      padding: 0.75em;
      max-width: 320px;
      border-radius: 5px;

      box-shadow: 0 5px 10px #0003;
      background-color: #fff;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        width: max-content;
        height: 2rem;
        display: grid;
        place-items: center;
        padding: 0 1em;

        background-color: #fff;
        color: #f33;
      }

      textarea {
        width: 100%;
        height: 5rem;
        resize: none;
        margin: 0.5em 0 0 0;
        border: 1px solid #666;
        border-radius: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.9em;
        padding: 0.5em;
      }
      .send {
        background-color: #f33;
        margin: 1em 0 0 0;
      }

      .error {
        color: #f33;
        font-size: 0.9em;
        margin: 0.25em 0;
      }
    }

    .reject {
      background-color: #f33;
      box-shadow: 0 5px 10px #f330;
      outline: 2px solid #f330;

      :hover {
        outline: 2px solid #f33f;
        box-shadow: 0 5px 10px #f338;
      }
    }
    .reject:disabled {
      background: #ddd;
      cursor: default;

      :hover {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

export const CommentsContainer = styled.div`
  margin-top: 1em;

  > label {
    display: block;
    margin: 0 0 1rem 0;

    span {
      display: block;
      text-align: right;
      margin: 0.25rem 0 0 0;
      font-size: 0.9rem;
    }
  }

  h3 {
    font-size: 1em;
    margin: 0 0 0.25em 0;
  }

  .comment {
    width: 100%;
    min-height: 5rem;
    border: 1px solid #666;
    border-radius: 5px;
    resize: none;
    padding: 0.75em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.85rem;
  }

  .list {
    display: flex;
    flex-flow: column;
    gap: 1em;
    margin-top: 0.5em;
  }

  .empty {
    padding: 1em;
    background: #f0f0f0;
    border-radius: 5px;
  }
`;
export const CommentContainer = styled.div`
  background: #f0f0f0;
  padding: 0.75em;
  border-radius: 5px;

  .text {
    font-size: 1rem;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;

    .from,
    .date {
      font-size: 0.75rem;
      color: #555;
    }

    .from {
    }
    .date {
    }
  }
`;

export const TicketTitleContainer = styled.div`
  display: flex;
  flex-flow: column-reverse;
  gap: 0.5rem;
  width: 100%;

  .inline {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  span {
    color: #${colors.hex.primary};
    font-weight: 600;
  }

  .title {
    display: flex;
    justify-content: space-between;

    h3 {
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: 600;
  }
`;

export const VehicleContainer = styled.div`
  .top {
    width: 100%;
    display: flex;
    gap: 1em;

    .select {
      width: 100%;
    }

    .wrapper_right {
      width: 40%;
    }
  }

  .bottom {
    display: flex;
    gap: 1em;
    margin-top: 1em;
  }

  .km-container,
  .total-container {
    display: flex;
    flex-flow: column;

    span {
      font-size: 0.8rem;
    }
    input {
      padding: 0.25em 0.25em 0.25em 0.5em;
      border: 1px solid #aaa;
      border-radius: 5px;
    }
  }

  .km-container {
    width: 100%;
  }
  .total-container {
    width: 100%;
  }
`;

export const Calculate = styled.button`
  font-size: 0.8rem;
  font-weight: 700;
  background: transparent;
  padding: 1em;
  border: 2px solid #${colors.hex.primary};
  color: #${colors.hex.primary};
  border-radius: 5px;

  :hover {
    color: #fff;
    background: #${colors.hex.primary};
  }
`;
