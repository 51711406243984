import { useDispatch } from "react-redux";
import React from "react";

import { useAlert } from "../../../context/alerts";

import { fromDataInput } from "../../../Utils/fromDataInput";
import { UpdateButton } from "./styles";
import { updateStockFromFile } from "../../../Redux/Actions/Warehouse";

function UpdateHandler({ data, onEvent }) {
  const { createAlert } = useAlert();

  const dispatch = useDispatch();

  const _onClick = async () => {
    onEvent("creating");

    const formData = fromDataInput(data);

    const code = await dispatch(updateStockFromFile(formData));
    onEvent("created");

    if (code) createAlert(SUCCESS_ALERT);
    else createAlert({ ...FAILURE_ALERT, text: code.message });
  };

  return (
    <UpdateButton className="calculate" onClick={_onClick} type="submit">
      Subir Archivo
    </UpdateButton>
  );
}
export default UpdateHandler;

const SUCCESS_ALERT = {
  type: "success",
  text: "Stock actualizado con éxito!",
  timeout: 2500,
};

const FAILURE_ALERT = {
  type: "error",
  timeout: 2500,
};
