import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Principal, WMS } from "../../Components/pedidos";
import { Andreani } from "../../Components/pedidos/detalles/Andreani";
import Table from "../../Components/Table";
import { PORT_API, PUBLIC_API } from "../../Redux/Actions/ActionsTypes";
import { Container } from "./styles";

import PopUp from "../../Components/PopUp";

const sections = [
  { label: "Principal", section: "principal" },
  { label: "WMS", section: "wms", onlySent: true },
  { label: "Andreani", section: "andreani", onlySent: true },
];

function PedidosDetails() {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [section, setSection] = useState(sections[0].section);

  const [handler, setHandler] = useState({
    isLoading: false,
    description: "",
    from: null,
  });

  const resetHandler = useCallback(
    () =>
      setTimeout(
        () => setHandler({ isLoading: false, from: null, description: "" }),
        5 * 1000
      ),
    []
  );

  const [changes, setChanges] = useState({
    direccion: "",
    localidad: "",
    provincia: "",
    cod_postal: "",
  });

  const onChange = ({ target: { name, value } }) => {
    setChanges((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    const p = async () => {
      const { data } = await axios.get(`${PORT_API}/pedidos/pedido/${id}`);
      setDetails({
        ...data,
        wms: {
          ...data.wms,
          status: data.wms.status.map((s) => ({
            ...s,
            ...(s.data ? JSON.parse(s.data) : {}),
          })),
        },
      });
    };
    p();
  }, [id]);

  const send = async () => {
    setHandler({ from: "WMS", isLoading: true, description: "" });
    const {
      data: { data },
    } = await axios.post(`${PORT_API}/pedidos/send/wms`, {
      nro_pedido: id,
    });

    if (data.d[0]) {
      setHandler({
        from: "WMS",
        description: data.d[0].mensaje,
        isLoading: false,
      });
    }

    resetHandler();
  };

  const enviarAndreani = async () => {
    if (!details) return null;
    const dir = (details.direccion || changes.direccion).trim().split(" ");
    const values = {
      cod_postal: changes.cod_postal || details.cod_postal,

      pais: "argentina",
      calle: dir.slice(0, dir.length - 1).join(" "),
      numero: dir[dir.length - 1],
      region: details?.provincia,

      localidad: changes.localidad || details?.localidad.toLowerCase(),
    };

    setHandler({ from: "Andreani", isLoading: true, description: "" });
    const { data: res } = await axios.post(
      `${PORT_API}/pedidos/etiqueta/${id}`,
      values
    );

    if (res.error)
      setHandler({
        isLoading: false,
        from: "Andreani",
        description: res.error.err.detail,
      });
    else
      setHandler({
        isLoading: false,
        from: "Andreani",
        description: "Etiqueta generada.",
      });

    resetHandler();
  };

  return (
    <Container>
      {handler.from ? (
        <div className="popup">
          <PopUp center full>
            <h3>{handler.from}</h3>
            {handler.isLoading ? (
              "Actualizando pedido"
            ) : (
              <p>{handler.description}</p>
            )}
          </PopUp>
        </div>
      ) : null}

      <div className="left"></div>

      <div className="right container">
        <div className="header">
          <h1>Nro. Pedido: {details?.nro_pedido}</h1>

          {details?.wasSent ? null : (
            <button onClick={send} className="send">
              Enviar a WMS
            </button>
          )}

          {details?.wasSent &&
          !details.etiqueta &&
          details.wms.status.filter((s) => s.status === "despachado")?.[0] &&
          details.nro_remito ? (
            <button onClick={enviarAndreani} className="send">
              Pre-Envio Andreani
            </button>
          ) : null}
        </div>

        <section>
          {!details?.nro_remito ? (
            <div className="error">
              La etiqueta no se puede generar ya que falta el numero de remito.
            </div>
          ) : null}

          <div className="section-header">
            {sections.map(({ label, section: s, onlySent }) =>
              (onlySent && details?.wasSent) || !onlySent ? (
                <button
                  key={`button-${s}`}
                  className={`section-btn ${s === section ? "active" : ""}`}
                  onClick={() => setSection(s)}
                >
                  {label}
                </button>
              ) : null
            )}
          </div>

          {section === sections[0].section && (
            <Principal onChange={onChange} inputs={changes} details={details} />
          )}

          {section === sections[1].section && details && details.wasSent && (
            <WMS id={details.nro_pedido} />
          )}

          {section === sections[2].section && details && details.etiqueta && (
            <Andreani
              etiqueta={details.etiqueta}
              url={
                details.wasSent
                  ? `${PUBLIC_API}/etiquetas/etiqueta-${details.nro_pedido
                      .trim()
                      .replace(/^0+/, "")}.pdf`
                  : null
              }
            />
          )}
        </section>

        {!details?.articulos ? null : (
          <section className="detalle">
            <div className="totals">
              <p>
                cantidad total:{" "}
                {details.articulos
                  .reduce((prev, curr) => prev + curr.cant_articulo, 0)
                  .toFixed(2)}
              </p>
              <p>
                volumen total:{" "}
                {details.articulos
                  .reduce((prev, curr) => prev + curr.vol_articulo, 0)
                  .toFixed(2)}
              </p>
            </div>
            <Table
              columns={[
                { value: "Cod. Articulo" },
                { value: "Detalle" },
                { value: "Cantidad" },
                { value: "Volumen" },
              ]}
              rows={
                details.articulos.map((d) => [
                  { value: d.cod_articulo },
                  { value: d.desc_articulo },
                  { value: d.cant_articulo },
                  { value: d.vol_articulo },
                ]) || []
              }
            />
          </section>
        )}
      </div>
    </Container>
  );
}

export default PedidosDetails;
