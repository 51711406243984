import axios from "axios";
import { LOGIN, LOGOUT, PORT_API } from "./ActionsTypes";

export function login(user) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`${PORT_API}/auth/login`, user);
      return dispatch({ type: LOGIN, payload: data });
    } catch (e) {
      if (e.response) return e.response.data;
      return e;
    }
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
      payload: {},
    });
  };
}
