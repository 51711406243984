//TYPE
export const PORT_API = process.env.REACT_APP_PORT_API + "/api";
export const PUBLIC_API = process.env.REACT_APP_PORT_API;

//AUTH
export const LOGIN = process.env.REACT_APP_LOGIN;
export const SIGNUP = process.env.REACT_APP_SIGUP;
export const LOGOUT = process.env.REACT_APP_LOGOUT;

// ORDERS
export const FIND_ALL_ORDERS = process.env.REACT_APP_FIND_ALL_ORDERS;

//WMS
export const FIND_ALL_PRODUCTS_WMS =
  process.env.REACT_APP_FIND_ALL_PRODUCTS_WMS;

//WAREHOUSES
export const FILTER_WAREHOUSE = "FILTER_WAREHOUSE";

export const DOWNLOAD_FILE_WAREHOUSES =
  process.env.REACT_APP_DOWNLOAD_FILE_WAREHOUSES;
export const UPLOAD_FILE_WAREHOUSE =
  process.env.REACT_APP_UPLOAD_FILE_WAREHOUSE || "UPLOAD_FILE";

//---------------URL------------------

//AUTH
export const URL_LOGIN = process.env.REACT_APP_URL_LOGIN;
export const URL_SIGNUP = process.env.REACT_APP_URL_SIGNUP;

//ORDERS
export const URL_FIND_ALL_ORDERS = "order/tango";

//WMS
export const URL_FIND_ALL_PRODUCTS_WMS =
  process.env.REACT_APP_URL_FIND_ALL_PRODUCTS_WMS;

//WAREHOUSES
export const URL_FILTER_WAREHOUSE = process.env.REACT_APP_URL_FILTER_WAREHOUSE;

export const URL_DOWNLOAD_FILE_WAREHOUSES =
  process.env.REACT_APP_URL_DOWNLOAD_FILE_WAREHOUSES;
export const URL_UPLOAD_FILE_WAREHOUSE =
  process.env.REACT_APP_URL_UPLOAD_FILE_WAREHOUSE;
