import axios from "axios";
import { useEffect, useState } from "react";
import { BiCloudDownload, BiFile } from "react-icons/bi";
import styled from "styled-components";
import useShow from "../../hooks/useShow";
import { PORT_API, PUBLIC_API } from "../../Redux/Actions/ActionsTypes";

function StockDownload() {
  const { state, toggle } = useShow({});
  const [files, setFiles] = useState([]);

  useEffect(() => {
    axios.get(`${PORT_API}/download`).then(({ data }) => {
      setFiles(
        data
          .map((f) => ({ ...f, date: new Date(f.date) }))
          .sort((a, b) => b.date.getTime() - a.date.getTime())
      );
    });
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Btn onClick={toggle}>
        <BiCloudDownload size={20} /> Descargar
      </Btn>

      {state && (
        <Container>
          {files.map(({ automated, name, date }) => (
            <FileContainer key={name}>
              <a
                className="left"
                href={`${PUBLIC_API}/files/stock/${name}`}
                download
              >
                <BiFile size={30} />
                <div className="right">
                  <p>Generado el {date.toLocaleString().split(",")[0]}</p>

                  {automated ? (
                    <span>Carga automatica</span>
                  ) : (
                    <span>Generado por usuario</span>
                  )}
                </div>
              </a>
            </FileContainer>
          ))}
        </Container>
      )}
    </div>
  );
}

export default StockDownload;

export const Btn = styled.button`
  background: #fff;
  border: 1px solid #888;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em 0.75em;
  margin: 0 0 0 auto;
  font-weight: 600;

  :hover {
    color: var(--primary);
    border: 1px solid var(--primary);
  }
`;

export const Container = styled.ul`
  display: flex;
  flex-flow: column;
  gap: 1em;
  padding: 2em;
  list-style: none;
  position: absolute;
  top: 120%;
  right: 0;
  width: 100%;
  background-color: #fff;
  width: max-content;
  border-radius: 5px;
  box-shadow: 0 5px 10px #0003;
`;

export const FileContainer = styled.li`
  list-style: none;

  .right {
    display: flex;
    flex-flow: column;

    span {
      font-size: 0.75rem;
    }
  }

  .left {
    text-decoration: none;
    align-items: center;
    color: #555;
    display: flex;
    gap: 0.5em;
    :hover {
      color: var(--primary);
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      text-decoration: underline;
    }
  }
`;
