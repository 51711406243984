export function calculateTimeDiff(date1, date2) {
  const d1 = typeof date1 === "string" ? new Date(date1) : date1;
  const d2 = typeof date2 === "string" ? new Date(date2) : date2;

  const diff = Math.abs(d1.getTime() - d2.getTime());

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  const str_d = days > 0 ? `${days} dias, ` : "";
  const str_h = hours > 0 ? `${hours} horas, ` : "";
  const str_m = minutes > 0 ? `${minutes} minutos ` : "";

  return `${str_d}${str_h}${str_m}`;
}

export function timeDiff(date1, date2) {
  const d1 = typeof date1 === "string" ? new Date(date1) : date1;
  const d2 = typeof date2 === "string" ? new Date(date2) : date2;

  const diff = Math.abs(d1.getTime() - d2.getTime());

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { days, hours, minutes };
}
