import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { BiLogOut, BiUser } from "react-icons/bi";

import { logout } from "../../Redux/Actions/Auth";

import styled from "styled-components";

function Avatar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const session = useSelector((store) => store.auth);

  const logoutNav = async () => {
    await dispatch(logout());
    navigate("/");
  };

  return session ? (
    <AvatarContainer>
      <NavLink to="/profile" className="button">
        <p>{session.display_name}</p>

        <div className="img-container">
          {session.avatar ? (
            <img src={session.avatar} alt="" />
          ) : (
            <BiUser className="icon" size={10} />
          )}
        </div>
      </NavLink>

      <div className="menu">
        <button className="avatar-link logout" onClick={logoutNav}>
          <p>Cerrar sesion</p>
          <BiLogOut size={20} />
        </button>
      </div>
    </AvatarContainer>
  ) : null;
}

export default Avatar;

const AvatarContainer = styled.div`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;

  .button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1em;
    height: 100%;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer;

    .img-container {
      width: 2.5rem;
      height: 2.5rem;
      background: #fff4;
      overflow: hidden;
      border-radius: 5px;
      display: grid;
      place-items: center;
      color: #fff;

      .icon {
        width: 50%;
        height: 50%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > p {
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: #fff;

      span {
        font-size: 0.75em;
        background: #000;
        padding: 0.25em 0.5em;
        border-radius: 5px;
      }
    }
  }

  .menu {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    background-color: #fff;
    padding: 0.5em;
    box-shadow: 0 5px 10px #0005;
    border-radius: 5px;
    min-width: 50%;
    width: max-content;
    display: none;

    .avatar-link {
      display: flex;
      align-items: center;
      gap: 1em;
      background: transparent;
      border: none;
      padding: 0.25em;

      > p {
        white-space: nowrap;
      }
    }
    .logout {
      color: #f55;
      font-weight: bold;
    }
  }

  :hover {
    .menu {
      display: block;
    }
  }
`;
