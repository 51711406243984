import styled from "styled-components";
import React from "react";

import PopUp from "../PopUp";
import Navbar from "../Navbar/NavBar";
import useShow from "../../hooks/useShow";
import GlobalStyle from "../../styles/global";

function Layout({ children }) {
  const { state } = useShow({ init: false });

  return (
    <>
      <GlobalStyle />
      <Navbar />
      {state && (
        <PopUp center>
          <Warning>
            <p className="title">Advertencia de inactividad.</p>
            <p className="description">
              Su sesion está por expirar, para evitarlo haga click en Continuar
            </p>
            <button className="continue">Continuar</button>
          </Warning>
        </PopUp>
      )}

      <FullPage>{children}</FullPage>
    </>
  );
}

export default Layout;

const FullPage = styled.div`
  display: grid;
  padding: var(--navbar-size) 0 0 0;
  width: 100%;
  min-height: 100vh;
`;

const Warning = styled.div`
  text-align: center;
  padding: 2em;

  .title {
    font-weight: 700;
    font-size: 1.5em;
    margin: 0 0 0.5em 0;
  }
  .description {
    font-weight: 500;
  }

  .continue {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #000;
    color: #fff;
    border: none;

    font-weight: bold;
    width: 100%;
    padding: 1em 0;
    margin: 3em 0 0 0;
  }
`;
